import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translate } from '../../../utils/Common';

const ResetPwdSuccess = () => {
    return (
        <div className='container text-center'>
            <Alert variant="success">
                <h4 className="h4 font-weight-normal">{translate("login.resetpwd.success.title")}</h4>
                <p className="mb-2">{translate("login.resetpwd.success.description1")}</p>
                <p className="mb-2 text-bold">#{translate("login.resetpwd.success.description2")}</p>
            </Alert>
            <Link to="/login" type="button" className="btn btn-primary-llego rounded-pill btn-lg text-uppercase mb-3">{translate("login.resetpwd.success.accept")}</Link>
        </div>
    );
}

export default ResetPwdSuccess;