import "./index.css";

const SectionBenefits = () => {
  return (
    <div id="section-benefits">
      <div className="title-benefits">
        <div className="benefits-titles-section">

        <div className="title-text-benefits">BENEFICIOS</div>
        <div className="d-flex justify-content-center">
        <img
          src="../img/logoTitle_llego_black.png"
          alt="logoTitle_llego_black"
          className="logoTitle_llego_benefits"
        />
        </div>

        
</div>

        {/* Hojas */}
        <div id="hojasA_benefits">
          <img
          src="../img/llego_pluma.png"
          alt="llego_pluma.png"
          className="hoja_llego_benefits" id="hoja1_benefits" />
           <img
          src="../img/llego_pluma.png"
          alt="llego_pluma.png"
          className="hoja_llego_benefits" id="hoja2_benefits" />
           <img
          src="../img/llego_pluma.png"
          alt="llego_pluma.png"
          className="hoja_llego_benefits" id="hoja3_benefits"/>
          </div>
      
        </div>
       

    <div className="container-list-benefits" >

      <div className="row">
        <div className="col-lg-4 col-6 cont-item-benefits">
          <img src="../img/planet_llego.png" alt="planet_llego.png" />
          <div>Logística verde</div>
          <img
          src="../img/img_llego_nube3.png"
          alt="img_llego_nube3.png"
          className="hoja_llego_benefits" id="nube1_benefits"/>
        </div>

        <div className="col-lg-4 col-6 cont-item-benefits">
          <img src="../img/clock_llego.png" alt="clock_llego.png" />
          <div>Horarios extendidos</div>
        </div>

        <div className="col-lg-4 col-6 cont-item-benefits">
          <img src="../img/call_bird_llego.png" alt="call_bird_llego.png" />
          <div>Atención personalizada</div>
        </div>

        <div className="col-lg-4 col-6 cont-item-benefits">
          <img src="../img/foco_llego.png" alt="planet_llego.png" />
          <div>Tecnología e innovación</div>
        </div>
        <div className="col-lg-4 col-6 cont-item-benefits">
          <img src="../img/map_locker_llego.png" alt="map_locker_llego.png" />
          <div>Ubicaciones estratégicas</div>
        </div>
        <div className="col-lg-4 col-6 cont-item-benefits">
          <img src="../img/box_fly_llego.png" alt="box_fly_llego.png" />
          <div>Vuelos diarios</div>

          <img
          src="../img/img_llego_nube3.png"
          alt="img_llego_nube3.png"
          className="hoja_llego_benefits" id="nube2_benefits"/>
            <img
          src="../img/img_llego_nube3.png"
          alt="img_llego_nube3.png"
          className="hoja_llego_benefits" id="nube3_benefits"/>

              {/* Hojas */}
        <div id="hojasB_benefits">
          <img
          src="../img/llego_pluma.png"
          alt="llego_pluma.png"
          className="hoja_llego_benefits" id="hoja1_benefits" />
           <img
          src="../img/llego_pluma.png"
          alt="llego_pluma.png"
          className="hoja_llego_benefits" id="hoja2_benefits" />
           <img
          src="../img/llego_pluma.png"
          alt="llego_pluma.png"
          className="hoja_llego_benefits" id="hoja3_benefits"/>
          </div>
      



          
        </div>

      </div>

    </div>
    
</div>
  );
};

export default SectionBenefits;
