import { useCallback } from "react";
import { getClientDomain, getToken } from "../utils/Common";


export const usePackages = () => {

    const sendAlertPackage = useCallback(async ( signal, form) => {
        const response = await fetch(`${getClientDomain()}/packages/alert`, {
                method: "POST",
                headers: {
                  authorization: `Bearer ${getToken()}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(form),

                signal 
        });
        
        const result = await response.json();
        
        return (result);
    }, []);

    const getPackagesByFilter = useCallback(async (signal, data) => {
      const response = await fetch(`${getClientDomain()}/packages/filter`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json',
              'authorization': `Bearer ${getToken()}`
          },
          signal: signal
      });
      
      const result = await response.json();
      if (result?.payload?.itemsFind?.length > 0) return (result.payload);
      else return ({
          ...result.payload,
          itemsFind: []
      });
  }, []);


    return { sendAlertPackage, getPackagesByFilter}
}