import { useEffect, useState } from "react";
import { FaWarehouse } from "react-icons/fa";
import { useUsers } from "../../../../hooks/useUsers";
import { parseStringToObject, translate } from "../../../../utils/Common";
import Spinner from "../../../../components/Spinner";

const LockerInfoPanel = () => {
    const [dataClient, setDataClient] = useState(null); 
    const { getUserAddressMiami } = useUsers();

    useEffect(() => {
      const abortController = new AbortController();
      const handleResponse = async () => {
        const response = await getUserAddressMiami(abortController.signal);
        setDataClient(response);        
      };
  
      handleResponse();
      return () => abortController.abort();
  
    }, []);

  return (
    <>
    <div className="dashboardCard m-0 w-100">

        <div className="d-flex justify-content-between">
              <div style={{fontSize:"20px"}} className="d-flex align-items-center" >
                <FaWarehouse className="mr-2"/>
                <div className="text-bold">{translate('global.addressMiami')}</div>
              </div>
            </div>
            <p className="text-left mt-2 pl-4">{translate('global.addressMiamiInstructions')}</p>
            <div className="dropdown-divider"></div>

          {dataClient ? (
              <>
            <div className="row text-left" style={{fontSize:"14px", padding:"0 18px"}}>
            <div className="col-12 p-2">
                <div className="text-bold">{translate("global.nameFull")}</div>
                <div className="form-control form-control-sm ">{dataClient?.NOMBRE?.toUpperCase() ?? ""} {dataClient?.APELLIDO?.toUpperCase() ?? ""} {dataClient?.PTYCOMPLETO ?? ""}</div>
            </div>
            <div className="col-12 p-2">
                <div className="text-bold">{translate("global.address")}</div>
                <div className="form-control form-control-sm">{dataClient?.address1?.toUpperCase() ?? ""}</div>
            </div>

            <div className="col-12 p-2">
                <div className="text-bold">{translate("global.address")} 2</div>
                <div className="form-control form-control-sm">{dataClient?.address2 ?? ""}</div>
            </div>

            <div className="col-6 col-lg-4 p-2">
                <div className="text-bold">{translate("global.city")}</div>
                <div className="form-control form-control-sm">{dataClient?.city?.toUpperCase() ?? ""}</div>
            </div>

            <div className="col-6 col-lg-4 p-2">
                <div className="text-bold">{translate("global.state")}</div>
                <div className="form-control form-control-sm">{dataClient?.state?.toUpperCase() ?? ""}</div>
            </div>

            <div className="col-12 col-lg-4 p-2">
                <div className="text-bold">{translate("global.zipCode")}</div>
                <div className="form-control form-control-sm">{dataClient?.zipCode?.toUpperCase() ?? ""}</div>
            </div>
                	
                  
            <div className="col-6 p-2">
                <div className="text-bold">{translate("global.country")}</div>
                <div className="form-control form-control-sm">{dataClient?.country?.toUpperCase() ?? ""}</div>
            </div>

            
            <div className="col-6 p-2">
                <div className="text-bold">{translate("global.phone")}</div>
                <div className="form-control form-control-sm">{dataClient?.phone?.toUpperCase() ?? ""}</div>
            </div>
          </div>
              </>
            ) : (
              <div className="d-flex justify-content-center p-2">
                <Spinner type={1} size={18} />
              </div>
            )}
    </div>
</>
  );
};

export default LockerInfoPanel;
