import {
  FaCalendarAlt,
  FaDollarSign,
  FaFileUpload,
  FaSave,
  FaSearchLocation,
  FaStore,
} from "react-icons/fa";
import { translate } from "../../../../utils/Common";
import { useForm } from "react-hook-form";
import { HiBellAlert } from "react-icons/hi2";
import { MdDescription } from "react-icons/md";
import UploaderFile from "../../../../components/UploaderFile";
import { usePackages } from "../../../../hooks/usePackages";
import { useContext, useState } from "react";
import ModalContext from "../../../../contexts/modal";
import Spinner from "../../../../components/Spinner";

const FormAlertPackage = () => {
  const { register, handleSubmit, reset, errors, setError } = useForm();
  const { sendAlertPackage } = usePackages();
  const [resetFile, setResetFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const modalContext = useContext(ModalContext);

  const handleSendAlertPackage = async (data, form) => {
    const abortController = new AbortController();
    const response = await sendAlertPackage(abortController.signal, form);

    if(response){
      if (response.code === 200) {
        modalContext.confirm(
          <>{translate("global.packageAlertSend")} ✅</>,
          <>
            <p>
              {translate("global.packageAlertSendSuccess")}
            </p>
            <ul>
              <li>
                <strong>{translate("global.numTracking")}:</strong> {data.tracking}
              </li>
              <li>
                <strong>{translate("global.description")}:</strong> {data.description}
              </li>
              <li>
                <strong>{translate("global.store")}:</strong> {data.store}
              </li>
              <li>
                <strong>{translate("global.cost")}:</strong> ${data.cost}
              </li>
              <li>
                <strong>{translate("global.estimatedDate")}:</strong> {data.dateEstimated}
              </li>
            </ul>
           <a href="/dashboard/package/list">
           <p className="text-bold">{translate("global.packageAlertGenerate1")}</p>
           </a> 
          </>,
          <button
            type="button"
            className="btn btn-sm btn-primary-llego"
            onClick={() => {
              modalContext.setIsOpenModal(false);
            }}
          >
            {translate("global.OK")}
          </button>,
          true
        );
  
        reset();
        setResetFile(true);
  
      } else if(response.code === 400 &&  response.message === "TRACKING_EXIST"){

        setError("tracking", {
          type: "manual",
          message: translate("dashboard.packages.loadpackages.formProvider.trackingRegistered")
        });

      } else {
        modalContext.confirm(
          translate("global.alerts.ops"),
          <span>{translate("dashboard.platforms.create.alert.error")}.</span>,
          <button
            type="button"
            className="btn btn-sm btn-fucsia"
            onClick={() => modalContext.setIsOpenModal(false)}
          >
            {translate("global.OK")}
          </button>,
          true
        );
      }
    }

    setIsLoading(false);

  };

  const onSubmit = (data) => {
    const file = data.fileSelect ? data.fileSelect[0] : null;
  
    const form = {
      ...data,
      fileData: null,
      fileType: null,
      fileName: null
    };
  
    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        
        form.fileData = base64String;
        form.fileType = file.type;
        form.fileName = file.name;
  
        setIsLoading(true);
        handleSendAlertPackage(data, form);
      };
  
      reader.readAsDataURL(file); 
    } else {
      setIsLoading(true);
      handleSendAlertPackage(data, form);
    }
  };
  

  return (
    <>
      <div className="dashboardCard m-0 w-100 text-left">
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div className="d-flex flex-column justify-content-start">
            <div className="d-flex justify-content-between mb-2">
              <div
                style={{ fontSize: "20px" }}
                className="d-flex align-items-center"
              >
                <HiBellAlert className="mr-2" />
                <div className="text-bold">
                  {translate("global.alertPackage")}
                </div>
              </div>
            </div>

            <div className="dropdown-divider"></div>

            <div
              className="row text-left"
              style={{ fontSize: "14px", padding: "0 18px" }}
            >
              <div className="col-12 p-2">
                <label className="text-bold">
                  <FaSearchLocation size={16} className="mr-2" />
                  {translate("global.numTracking")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="tracking"
                  ref={register}
                  required
                />
                 {errors.tracking && (
        <span className="text-danger">{errors.tracking.message}</span>
      )}
              </div>

              <div className="col-12 p-2">
                <label className="text-bold">
                  <MdDescription size={16} className="mr-2" />
                  {translate("global.description")}{" "}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  name="description"
                  className="form-control form-control-sm"
                  rows="3"
                  cols="50"
                  ref={register}
                  required
                ></textarea>
                    {errors.description && (
        <span className="text-danger">{errors.description.message}</span>
      )}
              </div>

              <div className="col-lg-4 col-md-6 p-2">
                <label className="text-bold">
                  <FaStore size={16} className="mr-2" />
                  {translate("global.store")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="store"
                  ref={register}
                  required
                />
                     {errors.store && (
        <span className="text-danger">{errors.store.message}</span>
      )}
              </div>

              <div className="col-lg-4  col-md-6 p-2">
                <label className="text-bold">
                  <FaDollarSign size={16} className="mr-2" />
                  {translate("global.cost")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  name="cost"
                  step="0.01"
                  placeholder="$ 0.00"
                  ref={register}
                  required
                />
                   {errors.cost && (
        <span className="text-danger">{errors.cost.message}</span>
      )}
              </div>

              <div className="col-lg-4 col-sm-12  col-md-6 p-2">
                <label className="text-bold">
                  <FaCalendarAlt size={16} className="mr-2" />
                  {translate("global.estimatedDate")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  name="dateEstimated"
                  ref={register}
                  min={today}
                  required
                />
                 {errors.dateEstimated && (
        <span className="text-danger">{errors.dateEstimated.message}</span>
      )}
              </div>
            </div>

            <div className="mt-4">
              <div>
              <label className="text-bold mb-0">
                <FaFileUpload size={16} className="mr-2" />
                {translate("global.uploadBill")}{" "}
              </label>
              <span style={{color:"gray"}} className="ml-2">{"JPG, JPEG, PNG, PDF (5 MB)"}</span>
              </div>
  {errors.dateEstimated && (
        <span className="text-danger">{errors.dateEstimated.message}</span>
      )}
              <UploaderFile
                name="fileSelect"
                register={register}
                isRequired={false}
                errors={errors}
                resetFile={resetFile} 
                accept=".pdf, .jpg, .jpeg, .png"
                setError={setError}
              />
            </div>

            <div className="d-flex justify-content-center mt-4">
              {
                isLoading ? <Spinner type={1} size={15}/> 
                : <button
                type="submit"
                className="btn btn-primary-llego rounded-pill"
                style={{ padding: "8px 30px" }}
              >
                <FaSave size={16} /> {translate("global.save")}
              </button> 
              }
              
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormAlertPackage;
