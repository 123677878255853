import { FaSave, FaUser } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import { useUsers } from "../../../hooks/useUsers";
import {  getToken, setUserSession, translate } from "../../../utils/Common";
import Spinner from "../../../components/Spinner";
import PlatformList from "../../../components/PlatformList";
import DatePickerBirth from "../../../components/DatePickerBirth";
import { useForm } from "react-hook-form";
import AuthContext from "../../../contexts/auth";
import ModalContext from "../../../contexts/modal";
import PhoneInputCustome from "../../../components/PhoneInput";

const FormClientInfoEdit = ({updateSidebarInfo}) => {
  const { getUserInformation, updateUserInformation } = useUsers();
  const UserContext = useContext(AuthContext);
  const [dataClient, setDataClient] = useState(null); 
  const [isEditing, setIsEditing] = useState(false);
  const [formDateBirth,setFormDateBirth] = useState(""); 
  const [formPhone, setFormPhone] = useState("");
  const modalContext = useContext(ModalContext);
  const { register, handleSubmit, reset, errors } = useForm();

  const handleUpdateData = async (data) => {
    const abortController = new AbortController();
    const response = await updateUserInformation({...data, "birthDate": formDateBirth, "phoneNumber": formPhone}, abortController.signal);

    if (response) {
      setIsEditing(false);

        switch (response?.Mensaje) {
          case "INFO_UPDATED_SUCCESS":
            UserContext.user.Nombre = data.name.toString().toUpperCase();
            UserContext.user.NombreSimple =
              data.name.toString().toUpperCase().substring(0, 1) +
              data.name.toString().toLowerCase().substring(1, data.name.length);
            UserContext.user.Apellidos = data.lastname.toString().toUpperCase();
            UserContext.user.ApellidoSimple =
              data.lastname.toString().toUpperCase().substring(0, 1) +
              data.lastname
                .toString()
                .toLowerCase()
                .substring(1, data.lastname.length);
            setUserSession(getToken(), UserContext.user);
            updateSidebarInfo();
  
            // handle response information in modal
            modalContext.confirm(
              translate("dashboard.userprofile.personal.successful_update"),
              translate("dashboard.userprofile.personal.success_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-primary-llego mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.OK")}
              </button>,
              true
            );
            break;
  
          case "INVALID_PARAMS":
            modalContext.confirm(
              translate("dashboard.userprofile.personal.error_header"),
              translate("dashboard.userprofile.personal.error_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-primary-llego mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.OK")}
              </button>,
              true
            );
            break;
  
          case "USER_NOT_AUTHORIZED":
            modalContext.confirm(
              translate("dashboard.userprofile.personal.error_header"),
              translate("dashboard.userprofile.personal.error_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-primary mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.OK")}
              </button>,
              true
            );
            break;
  
          default:
            // show error in modal
            modalContext.confirm(
              translate("dashboard.userprofile.personal.error_header"),
              translate("dashboard.userprofile.personal.error_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-primary-llego mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.OK")}
              </button>,
              true
            );
            break;
        }

        setIsEditing(false);
    
    }else{
      modalContext.confirm(
        translate("dashboard.userprofile.personal.error_header"),
        translate("dashboard.userprofile.personal.error_message"),
        <button
          type="button"
          key="1"
          className="btn btn-sm btn-primary-llego mr-2"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.OK")}
        </button>,
        true
      );
    }

    
    handleResponse();

  };
  
  const onSubmit = data => {
    handleUpdateData(data)
  };

  const abortController = new AbortController();
    const handleResponse = async () => {
      const response = await getUserInformation(abortController.signal);
      setDataClient(response);
      if (response) {
        setFormPhone(response.Telefono[0] ?? "");
        setFormDateBirth(response.FechaNacimiento ?? "");
      }
     
    };


  useEffect(() => {
    handleResponse();
    return () => abortController.abort();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="dashboardCard m-0 w-100 text-left">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column justify-content-start">
          <div className="d-flex justify-content-between mb-2">
            <div
              style={{ fontSize: "20px" }}
              className="d-flex align-items-center"
            >
              <FaUser className="mr-2" />
              <div className="text-bold">{translate("global.editPersonalInformation")}</div>
            </div>
          </div>

          <div className="dropdown-divider"></div>
         
          {dataClient ? (
            <>
            <div className="row text-left" style={{ fontSize: "14px", padding: "0 18px" }}>
              <div className="col-6 p-2">
                <label className="text-bold">{translate("global.name")}</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="name"
                  defaultValue={dataClient.Nombre}
                  ref={register}
                  disabled={!isEditing} 
                />
              </div>
              <div className="col-6 p-2">
                <label className="text-bold">{translate("login.signup.lastname")}</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  defaultValue={dataClient.Apellidos}
                  name="lastname"
                  ref={register}
                  disabled={!isEditing} 
                />
              </div>
              <div className="col-lg-6 col-sm-12 col-md-12 p-2">
                <label className="text-bold">{translate("login.signup.email")}</label>
                <input
                  type="email"
                  className="form-control form-control-md"
                  defaultValue={dataClient.Email}
                  name="email"
                  ref={register}
                  readOnly
                />
              </div>
              <div className="col-lg-6 col-12 p-2">
                <label className="text-bold">{translate("global.phone")}</label>
                <PhoneInputCustome country={'pa'} disabled={!isEditing} setValidatePhone={setFormPhone} defaultValue={dataClient.Telefono[0] || ""}/>
              </div>
              <div className="col-12 p-2">
                <label className="text-bold">{translate("dashboard.userprofile.personal.birthdate_label")}</label>
                <DatePickerBirth defaultDate={dataClient.FechaNacimiento} disabled={!isEditing} register={register} setFormDateBirth={setFormDateBirth} />
              </div>
              <div className="col-6 p-2">
                <label className="text-bold">{translate("global.gender")}</label>
                <select className="form-control form-control-md" defaultValue={dataClient.Sexo}  name="gender"
                  ref={register} disabled={!isEditing} >
    
                  <option value="H">{translate("global.male")}</option>
                  <option value="M">{translate("global.female")}</option>
                  <option value="">{translate("global.n/a")}</option>
                </select>
              </div>

              <div className="col-12 p-2">
                <label className="text-bold">{translate("global.lockerPreference")}</label>

        <PlatformList
           errors={errors}
           register={register}
           nameShow={translate("global.select")}
           readOnly={!isEditing} 
           idPlatform={dataClient.IdPlataforma}
           sizeInput={"form-control-md"}
         />
              </div>

            </div>
            <div className="d-flex justify-content-center mt-4">
            {isEditing ? (
              <div >
                 {/* <button type="button"  onClick={() => setIsEditing(false)} className="btn btn-secondary-llego rounded-pill mr-3" style={{ padding: "8px 20px" }}>
                       {translate("global.cancel")}
                    </button> */}
              <button type="submit" className="btn btn-primary-llego rounded-pill" style={{ padding: "8px 30px" }}>
                      <FaSave size={16} /> {translate("global.save")}
                    </button>
              </div>  
                  ) : (
                    <div
                      type="button"
                      className="btn btn-primary-llego rounded-pill"
                      style={{ padding: "8px 30px" }}
                      onClick={() => setIsEditing(true)} 
                    >
                      <FaEdit size={16} /> {translate("global.edit")}
                    </div>
                  )}
          </div>
            </>
          ) : (
            <div className="d-flex justify-content-center p-2">
              <Spinner type={1} size={18} />
            </div>
          )}
          

        </div>
        </form>
      </div>
    </>
  );
};

export default FormClientInfoEdit;
