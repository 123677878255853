import { useState } from "react";
import './index.css';
// components
import ForgotPwdSuccess from "./ForgotPwdSuccess";
import FormForgotPwd from "./FormForgotPwd";
import TraductorList from "../../../components/Traductor";

const ForgotPassword = () => {
    const [forgotPwdSuccess, setForgotPwdSuccess] = useState(false);

    return (
        <div className="login d-flex align-items-center justify-content-center" style={{
            backgroundImage: "url('/img/online_shopping.jpg')",
			backgroundSize: "cover"
          }}>
              <div className="position-fixed fixed-top p-4 d-flex flex-row-reverse">
					<TraductorList />
			</div>
            <div className="login-form shadow text-center">
            <img src="/img/LLEGO_logo.png" alt="Llego" height="80" className="mb-4" />

                { forgotPwdSuccess ? 
                    <ForgotPwdSuccess />
                : 
                    <FormForgotPwd setForgotPwdSuccess={ setForgotPwdSuccess } />
                }
            </div>
        </div>
    );
}

export default ForgotPassword;