import React, { useState } from 'react'
import ShowCanvasFilters from '../../../../components/ShowCanvasFilters';
import ItemPackagesCanvas from "../../../../components/ShowCanvasFilters/ItemPackagesCanvas";
import { translate } from '../../../../utils/Common';
import { usePackages } from '../../../../hooks/usePackages';

const MyPackages = () => {
    
    const {getPackagesByFilter} = usePackages();

    const [listClient, setListClient] = useState(null);

    const [configFilter, setConfigFilter] = useState({
        totalItems: 0,
        totalPages: 1,
        itemsPerPage: 9,
        currentPage: 1
      });

      const parametersSearch = [
        {
          id: "tracking",
          label: "Tracking",
        },
        {
          id: "package",
          label: "Paquete",
        },
        {
          id: "description",
          label: "Descripcion",
        }
      ];

    const abortController = new AbortController();
    const handleGetClients = async (data) => {
    setListClient(null);
    const response = await getPackagesByFilter(abortController.signal, data);
    
    setListClient(response?.itemsFind);
    setConfigFilter(response);
    
  };



  return (
     <div>
     
       <ShowCanvasFilters 
        Item={(props) => <ItemPackagesCanvas {...props} />}
        noItemsFound={translate("global.noResults")}
        actualPage={1}
        itemsShowing={listClient?.length}
        handleGetData={handleGetClients}
        itemArray={listClient}
        configFilter={configFilter}
        setConfigFilter={setConfigFilter}
        parameters={parametersSearch}
        />

   </div>
  )
}

export default MyPackages