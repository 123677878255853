import { useState } from "react";
import { CiUnread } from "react-icons/ci";
import { CiRead } from "react-icons/ci";


import "./index.css";
import { useSystem } from "../../../../hooks/useSystem";

const SectionPlans = () => {
  const [showSpecifications, setShowSpecifications] = useState(false);
  const { getTerminosCondiciones } = useSystem(); 

  const toggleSpecifications = () => {
    setShowSpecifications(!showSpecifications);
  };

  return (
    <div className="container-stepsplans" id="section-plans">
      {/* Title */}
      <div style={{position:"relative",zIndex:1}}>
      <div className="title-stepsplans" >
        <div className="mt-4">Compra en línea y retira en tu ubicación de preferencia, con horarios extendidos</div>
      </div>

<div className="container-box-plan">
  
      <div className="box-plan">

        <img src="../img/LLEGO_casita_pajaro.png" alt="LLEGO_casita_pajaro.png" width={"90px"} />

          <p className="mt-2">Envio aéreo  <br />Miami - Panamá</p>
          <div className="d-flex align-items-center" style={{gap:"8px"}}><h1>$4.50</h1><span>+ itbms</span></div>
          <p style={{color:"#F9ED32"}}>* Peso por libra</p>

          <p className="mt-2">- Vuelos diarios.<br />- De 3 a 5 días hábiles de envio <br />- Tu paquete llega al Boxit de tu preferencia y <br />tienes hasta 48 hrs para retirarlo.</p>
          
          <div>
            
            <div style={{display:"flex", gap:"10px"}}>
            <button className="btn btn-secondary-llego " onClick={toggleSpecifications}>{!showSpecifications ? <CiRead size={25}/> : <CiUnread size={25}/>} Especificaciones</button> 
            <a href="/registrar" className="btn btn-yellow-llego" >Regístrate aquí</a>
            </div>

          {
            showSpecifications && (
              <div style={{position:"relative"}}>
                <hr />
                <strong>Especificaciones:</strong>
                <p>El tarifario y beneficios ofrecidos aplican para el servicio aéreo de Miami a Panamá.</p>
                <p>La facturación de tus paquetes se realizan tomando en cuenta peso real y peso volumétrico. *Revisar bien los <a href="https://public.loquepedi.com/Terminos_y_Condiciones_Llego_App.pdf" download="Terminos_y_Condiciones_Llego_App.pdf"   target="_blank" rel="noopener noreferrer"  className="text-bold link-span" style={{color:"#424996"}}>Términos y condiciones</a></p>
                <p>De tener una dimensión mayor estipulado a las puertas de los lockers serán enviados a oficina principal.</p>
                <p>Pasadas las 48 hrs, se aplicará un costo logístico adicional.</p>

                <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hoja7_box_plan" />

              </div>
            )
          }

          {/*-- Hojas -- */}
            <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hoja_box_plan" />
            {/* <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hoja1_box_plan" /> */}
            <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hoja2_box_plan" />
            <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hoja3_box_plan" />
            <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hoja4_box_plan" />
            <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hoja5_box_plan" />
          </div>
      </div>

      </div>
     
      </div>
     

      {/* Elements Background */}
     <img src="../img/img_llego_nube1.png" className="nubes_stepsbuy" alt="img_llego_nube2.png" id="nube1_stepsplans" />
      <img src="../img/img_llego_nube3.png" className="nubes_stepsbuy" alt="img_llego_nube3.png" id="nube2_stepsplans" />
      <img src="../img/img_llego_nube3.png" className="nubes_stepsbuy" alt="img_llego_nube3.png" id="nube3_stepsplans" />
      <img src="../img/img_llego_nube1.png" className="nubes_stepsbuy" alt="img_llego_nube1.png" id="nube4_stepsplans" /> 

    </div>
  );
};

export default SectionPlans;
