import Header from "../../../components/Landing/Header";
import StepsBuyOnline from "./StepsBuyOnline";
import SectionPrincipal from "./SectionPrincipal";
import BannerDark from "./BannerDark";
import SectionPlans from "./SectionPlans";
import SectionLocations from "./SectionLocations";
import Faqs from "../../Dashboard/Faqs";
import Footer from "../../../components/Landing/Footer/Footer";
import SectionAboutUs from "./SectionAboutUs";
import SectionBenefits from "./SectionBenefits";

import "./index.css";
import BannerOpenAccount from "./BannerOpenAccount";

export default function Home() {
  
    localStorage.setItem("language", "es"); // Para asignar idioma español por defecto

  return (
    <>
      <Header />

      {/* --- SECCION PRINCIPAL --- */}
      <SectionPrincipal />

      {/* --- SECCION DE PASOS TRAER PAQUETES --- */}
      <StepsBuyOnline />

      {/*  --- Banner - Como abrir casillero ? */}
      {/* <div
        style={{
          background: "#f9ed32",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          padding: "2em 0",
        }}
      >
        <h2 style={{ color: "black", fontWeight: "bold" }}>
          ¿Cómo abres tu casillero en Miami?
        </h2>
        <div>
          <a
            href="/registrar"
            className="btn btn-third-llego mt-3"
            style={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Pa´lla voy
          </a>
        </div>
      </div> */}

      {/* Banner Dark Llego */}
      {/* <BannerDark /> */}

      {/* --- SECCION PLANES --- */}
      <SectionPlans />

      {/*  --- Banner - Como abrir casillero ? */}
      <BannerOpenAccount />
     

      {/* Banner Yellow Llego */}
      <BannerDark alternStyle />

      {/* Ubicaciones */}
      <SectionLocations />

      {/* Beneficios */}
      <SectionBenefits />

      {/* Acerca de nosotros */}
      <SectionAboutUs />

      {/* Preguntas Frecuentes */}
      <Faqs defaultText/>

      <Footer />
    </>
  );
}
