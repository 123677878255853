// stylesheet
import "./index.css";
// components
// icons
import FormClientInfoEdit from "./FormClientInfoEdit";
import FormChangePassword from "./FormChangePassword";

const UserProfile = (props) => {

  return (
    <>
      <div className="row px-3 py-2 mb-6 mt-4">
            <div className="col-lg-6 col-12 mb-3">
              <FormClientInfoEdit  updateSidebarInfo={props.updateSidebarInfo} />
            </div>
            <div className="col-lg-6 col-12">
              <FormChangePassword />
            </div>
          </div>
    </>
  );
};

export default UserProfile;
