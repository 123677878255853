import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { useState, useContext } from 'react';
import { getClDomain } from '../../utils/Common';
// provider
import ModalProvider from "../../contexts/modal/Provider";
// contexts
import AuthContext from '../../contexts/auth';
import SidebarContext from "../../contexts/sidebar";
// components
import Spinner from '../../components/Spinner';
import UserProfile from './UserProfile';
import Home from './Home';
import Faqs from './Faqs';
import './index.css';
import AlertPackages from './Packages/PackageAlert';
import MyPackages from './Packages/MyPackages';

// import CreatePlatform from './Platforms/CreatePlatform'

const Dashboard = () => {
	const userContext = useContext(AuthContext);
	const sidebarContext = useContext(SidebarContext);
    let { url } = useRouteMatch();
	const history = useHistory();
	const [update, setUpdate] = useState(false);

	const updateSidebarInfo = () => setUpdate(!update);

	if (!sessionStorage.getItem('token') && userContext.loading) {
		return  <div className="loader center">
				<Spinner type={0} size={40}/>
				</div>
	}
	if (!userContext.loading && userContext.user === null) history.push('/');

	return (
	<>
    	<div 
            id='mainLayout'
            className={"main-layout" + (sidebarContext.sidebarOpen ? "-open" : "")} 
            style={ getClDomain() !== 'bli.myboxit.com' ? { top: '82px', height: 'calc(100% - 82px)' } : { top: '60px', height: 'calc(100% - 60px)' } }
            onClick={ () => (sidebarContext.isMobile && sidebarContext.sidebarOpen) ? sidebarContext.closeSidebar() : null } >
            <ModalProvider>
                <Switch>
                    <Route path={`${url}`} component={Home} exact />
       
                    <Route path={`${url}/faqs`} component={ Faqs } exact/>                  

                    <Route path={`${url}/profile`} exact>
                        <UserProfile updateSidebarInfo={updateSidebarInfo} />
                    </Route>

                    <Route path={`${url}/package/alert`} exact>
                        <AlertPackages />
                    </Route>

                    <Route path={`${url}/package/list`} exact>
                        <MyPackages />
                    </Route>

                    {/* <Route path={`${url}/package/alert`} component={ AlertPackages } exact/>   */}
                    
                    <Redirect from={`${url}/*`} to={`${url}/`} />
                </Switch>
            </ModalProvider>
        </div>
    </>
	);
};

export default Dashboard;
