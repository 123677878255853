import FormAlertPackage from "./FormAlertPackage";


const AlertPackages = () => {
 
  return (
    <>
    <div className="row px-3 py-2 mb-6 mt-4">
      
          <div className="col-lg-12 col-12 mb-3">
            <FormAlertPackage />
          </div>

        </div>
  </>
  );
};

export default AlertPackages;
