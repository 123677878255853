import { useForm } from "react-hook-form";
import React, { useState, useRef, useContext } from 'react';
import { FaSave } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import AuthContext from "../../../contexts/auth";
import ModalContext from "../../../contexts/modal";

import * as FiIcons from 'react-icons/fi';
import { translate } from "../../../utils/Common";
import { useUsers } from "../../../hooks/useUsers";


const FormChangePassword = () => {
  
  const { handleSubmit, register, errors, watch, reset, setError, clearErrors } = useForm();
  const {  updatePasswordUser } = useUsers();
	const userContext = useContext(AuthContext);
	const modalContext = useContext(ModalContext);

    // para el cambio de rontraseña
    const newPwd = useRef({});
    newPwd.current = watch("newPwd","");
    const oldPwd = useRef({});
    oldPwd.current = watch("oldPwd","");
    // para validar números, letras y caracteres especiales
    const regexLetras = /[a-zA-Z]+/;
    const regexNum = /[0-9]+/;
	const regexOnlyNum = /^[0-9]*$/;
    const regexChar = /["#$%&/()=¿?¡!_.,+*']+/;
	
    // para ver las contraseñas
    const [isNewPwdVisible, setIsNewPwdVisible] = useState(false);
    const [isConfirmPwdVisible, setIsConfirmPwdVisible] = useState(false);
    const [isOldPwdVisible, setIsOldPwdVisible] = useState(false);
  
    const validatePwdFormat = (value) => {
			if (regexLetras.test(value) && regexNum.test(value) && regexChar.test(value) && !value.includes(' ')) return true;
			else return false;
		}
	
	const handleCancelUpdate = () => {
		clearErrors();
		// setValidateUpdatePwd(false);
		// setValidateUpdatePwdLocker(false);
	}

  const sendUpdatePwd = async (data) => {
		try {
                if (data.newPwd !== data.oldPwd) {
                    const result = await updatePasswordUser(data);

                    switch (result) {
                        case 'PASSWORD_UPDATED_SUCCESSFULLY':
                            clearErrors();
                            reset();
                            modalContext.confirm(
                                translate("global.alert.ready"),
                                translate('dashboard.userprofile.updatepsw.success_message_general'),
                                <button type="button" key="1" className="btn btn-sm btn-primary-llego mr-2" onClick={() => modalContext.setIsOpenModal(false)}>
                                    {translate("global.OK")}
                                </button>,
                                true
                            );
                            break;
                        case 'OLD_PASSWORD_INVALID':
                            setError('oldPwd', {
                                type: '',
                                message: translate('dashboard.userprofile.updatepsw.oldpassword_invalid')
                            });
                            break;
                        case 'USER_NOT_FOUND':
                        case 'SERVER_ERROR':
                            setError('newPwd', {
                                type: '',
                                message: translate('dashboard.userprofile.updatepsw.server_error')
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    setError('newPwd', {
                        type: '',
                        message: translate('dashboard.userprofile.updatepsw.newpassword_match')
                    });
                }
		} catch (error) {
			console.log(error);
		}
	}
  
  return (
    <>
      <form onSubmit={handleSubmit(sendUpdatePwd)}>
      <div className="dashboardCard m-0 w-100 text-left">
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex justify-content-between mb-0">
            <div
              style={{ fontSize: "20px" }}
              className="d-flex align-items-center"
            >
              <FaKey className="mr-2" />
              <div className="text-bold">{translate("global.modifyPwd")}</div>
            </div>
          </div>

          <p className="text-left mt-0 mb-0 pl-4">
            {translate("global.markedFields")} <span className="text-danger">*</span> {translate("global.areRequired")}
          </p>
          <div className="dropdown-divider"></div>

          <div
            className="row text-left"
            style={{ fontSize: "14px", padding: "0 18px" }}
          >
            <div className="col-12 p-2">
              <div className="text-bold">{translate("dashboard.userprofile.updatepsw.old_password")} <span className="text-danger">*</span></div>
              <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text span-eye-pwd" onClick={() => setIsOldPwdVisible(!isOldPwdVisible)}>
                                    {isOldPwdVisible ?
                                        <FiIcons.FiEye size={16} className="text-dark" />
                                        :
                                        <FiIcons.FiEyeOff size={16} className="text-dark" />
                                    }
                                </span>
                            </div>
                            <input type={isOldPwdVisible ? "text" : "password"} name="oldPwd" className={"form-control form-control-sm" + (errors.oldPwd ? " is-invalid" : "")} id="inputOldPwd" ref={register({
                                required: {
                                    value: true,
                                    message: translate('dashboard.userprofile.updatepsw.invalid_password')
                                },
                                minLength: {
                                    value: 6,
                                    message: translate('dashboard.userprofile.updatepsw.minlength_oldpwd', { min: 6 })
                                },
                                maxLength: {
                                    value: 50,
                                    message: translate('dashboard.userprofile.updatepsw.maxlength_oldpwd', { max: 50 })
                                },  
                                // pattern: {
                                //     value: /[A-Z]/,
                                //     message: translate("dashboard.userprofile.updatepsw.has_uppercase"),
                                //   },
                            })} />
                            {errors.oldPwd && <div className="invalid-feedback">{errors.oldPwd.message}</div>}
                        </div>
            </div>
            <div className="col-12 p-2">
              <div className="text-bold">{translate('dashboard.userprofile.updatepsw.password')} <span className="text-danger">*</span></div>
              <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text span-eye-pwd" onClick={() => setIsNewPwdVisible(!isNewPwdVisible)}>
                                {isNewPwdVisible ?
                                    <FiIcons.FiEye size={16} className="text-dark" />
                                    :
                                    <FiIcons.FiEyeOff size={16} className="text-dark" />
                                }
                            </span>
                        </div>
                        <input type={isNewPwdVisible ? "text" : "password"} className={"form-control form-control-sm" + (errors.newPwd ? " is-invalid" : "")} id="inputPwd" name="newPwd" ref={register({
                            required: {
                                value: true,
                                message: translate('dashboard.userprofile.updatepsw.invalid_password')
                            },
                            minLength: {
                                value: 6,
                                message: translate('dashboard.userprofile.updatepsw.minlength', { min: 6 })
                            },
                            maxLength: {
                                value:  50,
                                message: translate('dashboard.userprofile.updatepsw.maxlength_general', { max: 50 })
                            },
                            pattern: {
                                value:  /[A-Z]/,
                                message:  translate("dashboard.userprofile.updatepsw.has_uppercase"),
                              },
                            validate: value => validatePwdFormat(value) || translate('dashboard.userprofile.updatepsw.validate')
                        })} />
                        {errors.newPwd && <div className="invalid-feedback">{errors.newPwd.message}</div>}
                    </div>
            </div>

            <div className="col-12 p-2">
              <div className="text-bold">{translate('dashboard.userprofile.updatepsw.confirm_password')} <span className="text-danger">*</span></div>
              <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text span-eye-pwd" onClick={() => setIsConfirmPwdVisible(!isConfirmPwdVisible)}>
                                {isConfirmPwdVisible ?
                                    <FiIcons.FiEye size={16} className="text-dark" />
                                    :
                                    <FiIcons.FiEyeOff size={16} className="text-dark" />
                                }
                            </span>
                        </div>
                        <input type={isConfirmPwdVisible ? "text" : "password"} className={"form-control form-control-sm" + (errors.pwdConfirm ? " is-invalid" : "")} id="pwdConfirm" name="pwdConfirm" ref={register({
                            required: {
                                value: true,
                                message: translate('dashboard.userprofile.updatepsw.invalid_password')
                            },
                            minLength: {
                                value: 6,
                                message: translate('dashboard.userprofile.updatepsw.minlength', { min: 6 })
                            },
                            maxLength: {
                                value: 50,
                                message: `${translate('dashboard.userprofile.updatepsw.maxlength_locker')} ${50}) ${translate('dashboard.userprofile.updatepsw.characters')}`
                            },
                            validate: value => value === newPwd.current || translate('dashboard.userprofile.updatepsw.passwords_not_match')
                        })} />
                        {errors.pwdConfirm && <div className="invalid-feedback">{errors.pwdConfirm.message}</div>}
                    </div>
            </div>
          </div>

                    <div className="ml-3 mt-3 mb-3">
                        <div className="text-left">
                            <p className="font-xsmall m-0 p-0 text-bold">{translate('dashboard.userprofile.updatepsw.requirements')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_1')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_2')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_3')}</p>
                            <p className="font-xsmall m-0 p-0">{translate("dashboard.userprofile.updatepsw.requirement_5")}</p>
                          </div>
                      </div>

          <div className="d-flex justify-content-center mt-2">
            <button className="btn btn-primary-llego rounded-pill" style={{padding:"8px 30px"}}>
              <FaSave size={16} /> {translate("global.modify")}
            </button>
          </div>

        </div>
      </div>

      </form>
    </>
  );
};

export default FormChangePassword;
