import { useCallback } from "react";
import { getClientDomain, getToken, translate } from "../utils/Common";

export const useSystem = () => {

    const getCarriers = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain()}/system/carriers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, []);

    const getPaymentMethodsUsersBLI = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain()}/system/paymentMethodsUsersBLI`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, []);

    const getTerminosCondiciones = async () => {
        try {
            const response = await fetch(`${getClientDomain()}/files/pdf/terminos`, {
              method: "GET",
              accept: "application/json",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            });
      
            const blob = await response.blob();
            if (blob) {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `Términos_y_Condiciones_Llego_App.pdf`
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            }
          } catch (error) {
            console.log("error 1:", error);
          }
    }


    return { getCarriers, getPaymentMethodsUsersBLI, getTerminosCondiciones }
}