import { useState } from "react";
// import { BsTranslate } from "react-icons/bs";
import {MdGTranslate} from "react-icons/md"
import "./index.css";
import { translate } from "../../utils/Common";

const TraductorList = ({darkMode}) => {
  const langStorage = localStorage.getItem("language");
  const [language, setLanguage] = useState(langStorage);
  const [modalLanguages, setModalLanguages] = useState(false);

  const handleModalLanguage = () => {
    setModalLanguages((prevState) => !prevState);
  };

  const handleSelectLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", (lang));
    setModalLanguages((prevState) => !prevState);
    window.location.reload();
  };

  return (
    <div style={{position:"relative"}}>     
      <button
            type="button"
            className="btn dropdown-toggle glass-effect"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{color: darkMode ? "black" : "white", border:darkMode? "1px solid transparent" : "1px solid transparent", position:"absolute", top:"-20px", right:"0" }}

          >
            <MdGTranslate size={20} style={{filter: "drop-shadow(1px 1px 1px black)"}}/>{" "}
           <span className="name-user-navbar">{language.toUpperCase()}</span>
          </button>

      <div className="dropdown-menu mt-2 drop-traduct"  style={{background:"white", height: "fit-content"}} >
            <div className="dropdown-item d-flex" onClick={()=>{handleSelectLanguage("es")}}>
            <div style={{backgroundImage:"url(/img/es_flag.png)", backgroundSize: 'cover', width: 20, height: 20}} className="mr-2"/>
              <span>{translate("translate.es")}</span>
            </div>

            <div className="dropdown-divider"></div>

            <div className="dropdown-item d-flex" onClick={()=>{handleSelectLanguage("en")}}>
            <div style={{backgroundImage:"url(/img/usa_flag.png)", backgroundSize: 'cover', width: 20, height: 20}} className="mr-2"/>
              <span>{translate("translate.en")}</span>
            </div>

            <div className="dropdown-divider"></div>

            <div className="dropdown-item d-flex" onClick={()=>{handleSelectLanguage("pt")}}>
            <div style={{backgroundImage:"url(/img/br_flag.png)", backgroundSize: 'cover', width: 20, height: 20}} className="mr-2"/>
              <span>{translate("translate.pt")}</span>
            </div>
          </div>

    </div>
  );
};

export default TraductorList;
