import React, { useState } from 'react';
import ResetPwdSuccess from './ResetPwdSuccess';
import FormResetPwd from './FormResetPwd';
import './index.css';
import TraductorList from "../../../components/Traductor";

const ResetPwd = (props) => {
    const id = props.match.params.id;

    // confirm if the server response ok
    const [resetPwdSuccess, setResetPwdSuccess] = useState(false);

    return (
        <div className="login d-flex align-items-center justify-content-center" style={{
            backgroundImage: "url('/img/online_shopping.jpg')",
			backgroundSize: "cover"
          }}>
            <div className="position-fixed fixed-top p-4 d-flex flex-row-reverse">
					<TraductorList />
			</div>
            <div className="login-form shadow text-center">
            <img src="/img/LLEGO_logo.png" alt="Llego" height="90" className="mb-4" />


                { resetPwdSuccess ?
                    <ResetPwdSuccess />
                :
                    <FormResetPwd setResetPwdSuccess={ setResetPwdSuccess } id={ id } />
                }
            </div>
        </div>
    );
}

export default ResetPwd;