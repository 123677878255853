import { useState, useEffect } from "react";
import { usePlatforms } from "../hooks/usePlatforms";
import { translate } from "../utils/Common";
import { FaLocationDot } from "react-icons/fa6";


const PlatformPublicList = ({
  register,
  errors,
  nameShow,
  idShow,
  readOnly,
  idPlatform,
  sizeInput,
}) => {
  const { getAllPlatformsPublic } = usePlatforms();
  const [plataformasList, setPlataformasList] = useState([]);

  const [plataformaSeleccionada, setPlataformaSeleccionada] = useState(idPlatform);

  useEffect(() => {
    const abortController = new AbortController();

    const handlePlatforms = async () => {
      const platforms = await getAllPlatformsPublic(abortController.signal);
      setPlataformasList(platforms);
    };

    setTimeout(() => { 
      handlePlatforms();
    }, 100);
    
    return () => abortController.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handlePlataformaChange = (event) => {
    setPlataformaSeleccionada(parseInt(event.target.value)); 
  };

  const addressPlatformSelected = plataformasList.find(plataforma => plataforma.boxitLocationId === plataformaSeleccionada)?.locationAddress;
  const addressLocationSelected = plataformasList.find(plataforma => plataforma.boxitLocationId === plataformaSeleccionada)?.BoxitUbicacion;

  return (
    <div className="d-flex flex-column">
      <select
        className={`${sizeInput ?? "form-control-sm"} form-control font-small`}
        readOnly={readOnly}
        name="plataforma"
        value={plataformaSeleccionada}
        onChange={handlePlataformaChange}
        required
        ref={register}
      >
          <option
              className="font-small"
              value=""
            >
              {translate("global.select")}
            </option>
        {plataformasList.length > 0 ? (
          plataformasList.map((boxit) => (
            <option
              key={boxit.boxitLocationId}
              className="font-small"
              value={boxit.boxitLocationId}
            >
              {boxit.locationName}
            </option>
          ))
        ) : (
          <option defaultValue className="font-small">
            {translate("global.noPlatforms")}
          </option>
        )}
      </select>
      {errors.plataforma && (
        <span className="text-error">{errors.plataforma.message}</span>
      )}

      {addressPlatformSelected && (
        <div className="bd-callout bd-callout-warning">
          <FaLocationDot style={{ color: "#F9ED32" }} className="mr-2" />
          {addressPlatformSelected}
          {addressLocationSelected && (
                    <a href={addressLocationSelected ?? ""} target="_blank"  rel="noreferrer" style={{ color: "#F9ED32" }} className="ml-2">
                   <u>{translate("global.viewMap")}</u>
                  </a>
                  )}
        </div>
      )}
    </div>
  );
};

export default PlatformPublicList;
