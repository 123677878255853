import React from "react";
import { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AuthContext from "../../../contexts/auth";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { translate } from "../../../utils/Common";
import "./index.css";
import Spinner from "../../../components/Spinner";

const Activate = (props) => {
  const userContext = useContext(AuthContext);
  const { token, country } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState("");
  const [codeResponse, setCodeResponse] = useState(false);

  useEffect(() => {
      const activateAccount = async () => {
        const result = await userContext.activateAccount(country, token);
          setResponse(result.message);
          setCodeResponse(result.icon);
          setIsLoading(false);
      };

      setTimeout(() => activateAccount(), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div
      className="login d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: "url('/img/online_shopping.jpg')",
			backgroundSize: "cover"
      }}
    >
      <div className="login-form shadow text-center">
        <div className="mb-3">
          <img src="/img/LLEGO_logo.png" alt="Llego" height="80" />
          <hr />
          {isLoading ? (
            <Spinner type={1} size={50} />
          ) : (
            <>
              {codeResponse ? (
                <MdCheckCircle size={60} color="green" />
              ) : (
                <MdCancel size={60} color="red" />
              )}
              <h4 style={{color:"black"}}>{translate("login.activate.title")}</h4>
              <p>{response}</p>
            </>
          )}
        </div>

        <Link to="/login">
          <button
            className="btn btn-primary-llego rounded-pill text-uppercase m-2"
            type="back"
          >
            {translate("global.goBack")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Activate;
