import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { getClDomain, translate } from "../../utils/Common";
// contexts
import AuthContext from "../../contexts/auth";
import SidebarContext from "../../contexts/sidebar";
// icons
import * as FaIcons from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

import "./index.css";
import TraductorList from "../../components/Traductor";

const Navbar = () => {
  const userContext = useContext(AuthContext);
  const sidebarContext = useContext(SidebarContext);

  return (
    <div
      className={`d-flex justify-content-between align-items-center shadow-sm nav${
        !sidebarContext.sidebarOpen ? "-close" : ""
      }`}
      style={getClDomain() !== "loquepedi.com" ? { top: "22px" } : { top: "0" }}
    >
      {/* Barras horizontales */}
      <div
        className="bars-div logo-princ-navbar"
        onClick={() => sidebarContext.showSidebar(!sidebarContext.sidebarOpen)}
      >
        <FaIcons.FaBars className="bars" size={20} />
      </div>

      {/* Logo Llego */}
      <div className="d-flex align-items-center pl-3">
        <Link
          to="/dashboard"
          onClick={() =>
            sidebarContext.handleChangeCurrentPage(
              translate("sidebar.options.start")
            )
          }
        >
          <div
            className="logo-boxit logo-princ-navbar pt-2"
            style={{
              backgroundImage: sidebarContext.sidebarOpen
                ? `url(/img/logo-llego-bordes.png)`
                : "url(/img/logo-llego-bordes.png)",
              width: sidebarContext.sidebarOpen ? 55 : 55,
              height: sidebarContext.sidebarOpen ? 55 : 55,
            }}
          />
        </Link>
      </div>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{ gap: "8px" }}
      >
        {/* Perfil */}
        <div>
            <TraductorList />
          </div>
        <div className="btn-group mr-2">
        

          <button
            type="button"
            className="btn btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FaUserCircle size={20} />{" "}
            <span className="name-user-navbar">
              {userContext.user?.Nombre
                ? userContext.user?.Nombre + " " + userContext.user?.Apellidos
                : ""}
            </span>
          </button>
          <div className="dropdown-menu" style={{background:"white", height: "fit-content"}}>
            <Link
              to="/dashboard/profile"
              onClick={() =>
                sidebarContext.handleChangeCurrentPage(
                  translate("global.editProfile")
                )
              }
            >
              <div className="dropdown-item">
                <FaUser /> {translate("navbar.profile")}
              </div>
            </Link>

            <div className="dropdown-divider"></div>

            <Link to="/login" onClick={() => userContext.signOut()}>
              <div className="dropdown-item">
                {" "}
                <IoLogOutOutline /> {translate("navbar.logout")}
              </div>
            </Link>

          </div>

       

        </div>
      </div>
    </div>
  );
};

export default Navbar;
