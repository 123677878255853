import React from "react";
import {BsWhatsapp} from "react-icons/bs";
import {FiMail} from "react-icons/fi"
import {AiFillPhone, AiFillInstagram} from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
// import {IoLogoFacebook} from "react-icons/io";
import packageJson from "../../../../package.json";

import "./footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer id="footer">
        <div className="row" >

          <div className="col-lg-3 col-sm-12 mb-3">
            <img src="../img/logo-llego-bordes.png" alt="Boxit" width="80" />
          </div>

          <div className="col-lg-3 col-sm-6 mb-3">
            <h5 className="mb-4 titles-footer">Enlaces de Interés</h5>
            <ul className="footer-ul">
             <a href="/"><li>Home</li></a>
             <a href="#section-stepsbuy"><li>Trae tus paquetes</li></a>
             <a href="#section-benefits"><li>Beneficios</li></a>
              <a href="#section-locations"><li>Sucursales</li></a>
              <a href="#section-plans" ><li>Tarifas</li></a>
              <a href="#section-aboutUs"><li>Nosotros</li></a>
              <a href="#section-questions-frecuents"><li>Preguntas frecuentes</li></a>
              <a href="https://public.loquepedi.com/Terminos_y_Condiciones_Llego_App.pdf" target="_blank" rel="noopener noreferrer"><li>Términos y Condiciones</li></a>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6 mb-3"  id="section-contact">
            <h5 className="mb-4 titles-footer">Contáctenos</h5>
            <ul className="footer-ul">
           <a href="mailto:llego@loquepedi.com"><li><FiMail/><span className="ml-2">llego@loquepedi.com</span></li></a>
            <a href="tel:+507317-1014"><li><AiFillPhone/><span className="ml-2">(+507) 317-1014</span></li></a>
           <a href="https://wa.me/50763494559?text=Hola%20Boxit%20,%20¿Podrías%20proporcionarme%20más%20información?" target="_blank" rel="noopener noreferrer"> <li><BsWhatsapp/><span className="ml-2">(+507) 6349-4559</span></li></a>
           <a href="https://maps.app.goo.gl/3LAPzBu8c7wPofgZ8" target="_blank" rel="noopener noreferrer"> <li><FaLocationDot/><span className="ml-2">Edifico BMW PTY calle 50 con Via Porras, Planta 6, Oficina 6A de Lunes a Viernes de 8:00 am a 5:00 pm</span></li></a>
            </ul>
          </div>

          <div className="col-lg-3 social-media col-sm-12 mb-3">
            <h5 className="mb-4 titles-footer">Redes Sociales</h5>
            <a href="https://www.instagram.com/loquepedi" target="_blank" rel="noopener noreferrer"><AiFillInstagram size={35} className="mr-2"/></a>
              <a href="https://wa.me/50763494559?text=Hola%20Llegó%20,%20¿Podrías%20proporcionarme%20más%20información?" target="_blank" rel="noopener noreferrer"><BsWhatsapp size={25} className="mr-2"/></a>
            {/* <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><IoLogoFacebook size={35} className="mr-2"/></a> */}
            {/* <a href="https://www.linkedin.com/loquepedii" target="_blank" rel="noopener noreferrer"><AiFillLinkedin size={35} className="mr-2"/></a> */}
          </div>
        </div>
        <hr style={{marginTop:"20px"}}/>
        <div className="d-flex justify-content-center">© {currentYear} Llegó Lo que pedí | All Rights Reserved -   <span className="pl-1"> {" "}v{packageJson.version}
          </span> </div>

      </footer>
    </>
  );
};

export default Footer;
