import React, { useContext } from 'react';
import AuthContext from '../../../contexts/auth';
import './index.css';

// components
import AdminHome from './AdminStats';
import UserHome from './UserStats';
import CounterHome from './CounterHome';
import { translate } from '../../../utils/Common';
import ClientInfoPanel from './DataPersonal/ClientInfoPanel';
import LockerInfoPanel from './DataPersonal/LockerInfoPanel';

// vista de home insights
const Home = () => {

    let user = JSON.parse(sessionStorage.getItem('user'));
    
    const getName = () => {

        if (user.NombreSimple.indexOf(' ')) {
            return user.NombreSimple.split(' ')[0];
        }
        else {
            return user.NombreSimple;            
        }
    }

    const greetings = () => {
        const hour = new Date().getHours();
        if (hour >= 0 && hour < 12){
            return translate("dashboard.home.greeting.morning");
        }
        else if (hour >= 12 && hour < 19) {
            return translate("dashboard.home.greeting.afternoon");
        }
        else if (hour >= 19) {
            return translate("dashboard.home.greeting.evening");
        }
    }

    return (
        <>
            <div className="dashboardCardAltern text-left mb-2">
                <h4 className="m-0">👋 { greetings() + (user ? getName() : 'Usuario') }!</h4>
                <p className="m-0">{translate("dashboard.home.welcome")} <span className="text-bold">{translate("dashboard.home.team")}</span></p>
            </div>

            <div className="row px-3 py-2 mb-6">
                <div className='col-lg-6 col-12 mb-3'>
                    <ClientInfoPanel />
                </div>
                <div className='col-lg-6 col-12'>
                    <LockerInfoPanel />
                </div>
            </div> 
        </>
    );
}

export default Home;