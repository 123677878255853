import React from 'react';
import {translate} from "../../utils/Common"

const NotFound = (props) => {
    return (
        <div className="login signup-container d-flex align-items-center justify-content-center"   style={{ background: "#F9ED32", height: "100vh" }}>
            <div className="container text-center text-light">
                <h1  style={{ color:"black"}}>:( Error 404</h1>
                <p className="font-small"  style={{ color:"black"}}>{translate("global.error404")}</p>
            </div>
        </div>
    );
}

export default NotFound;