import "./index.css";

const SectionPrincipal = ({alternStyle}) => {

  const logo = alternStyle ? "../img/logoTitle_llego_black.png" : "../img/logoTitle_llego_white.png";
  const backgroundStyle = alternStyle ? "#f9ed32" :  "#000";

  return (
<div className="banner-container" style={{background: backgroundStyle}}>

{/* Logo */}
<img
  src={logo}
  alt="logoTitle_llego_black"
  style={{ height: "80px", position: "relative",  zIndex: "6",  filter: !alternStyle && "drop-shadow(0 2px 15px rgba(0, 0, 0, 15))" }}
/>
{/* Box, Plumas */}
<img src="../img/llego_pluma.png" alt="llego_pluma.png" style={{ height: "4em", position: "absolute", left: "4em", top: "10%",zIndex:5 }}className="floating-element" />
<img src="../img/boxLlego.png" alt="boxLlego.png" style={{ height: "4em", position: "absolute", top: "95%" , left: "16em", zIndex:5 }} className="floating-element-slow"/>
<img src="../img/llego_pluma.png" alt="llego_pluma.png" style={{ height: "3em", position: "absolute",top: "15%" , left: "28em" , zIndex:5}} className="floating-element"/>
<img src="../img/boxLlego.png" alt="boxLlego.png" style={{ height: "4em", position: "absolute", top: "30%" , left: "40em",zIndex:5 }} className="floating-element-slow" />

{/* <img src="../img/boxLlego.png" alt="boxLlego.png" style={{ height: "4em", position: "absolute", top: "85%" , left: "52em", opacity:"1",zIndex:5 }} className="floating-element" /> */}

<img src="../img/llego_pluma.png" alt="llego_pluma.png" style={{ height: "4em", position: "absolute", top: "30%" , left: "64em", zIndex:5 }} className="floating-element-slow"/>
<img src="../img/boxLlego.png" alt="boxLlego.png" style={{ height: "3em", position: "absolute",top: "15%" , left: "76em", zIndex:5 }} />
<img src="../img/llego_pluma.png" alt="llego_pluma.png"  style={{ height: "3em", position: "absolute", top: "40%", left: "88em", zIndex:5 }} className="floating-element-slow" />
<img src="../img/boxLlego.png" alt="boxLlego.png" style={{ height: "4em", position: "absolute", top: "60%" , left: "100em", zIndex:5 }} />

<img src="../img/llego_pluma.png" alt="llego_pluma.png" style={{ height: "4em", position: "absolute", top: "30%" , left: "112em", zIndex:5 }} className="floating-element-slow"/>
<img src="../img/boxLlego.png" alt="boxLlego.png" style={{ height: "3em", position: "absolute",top: "15%" , left: "124em", zIndex:5 }} className="floating-element"/>
<img src="../img/llego_pluma.png" alt="llego_pluma.png"  style={{ height: "3em", position: "absolute", top: "40%", left: "136em", zIndex:5 }} className="floating-element-slow"/>
<img src="../img/boxLlego.png" alt="boxLlego.png" style={{ height: "4em", position: "absolute", top: "55%" , left: "148em", zIndex:5 }} className="floating-element"/>

{/* Nubes */}
<img src="../img/img_llego_nube3.png"  alt="img_llego_nube3.png" style={{filter: "blur(3px)", height:"8em", position: "absolute", left: "-100px", bottom: "-50px" }}/>
<img src="../img/img_llego_nube2.png"  alt="img_llego_nube3.png" style={{filter: "blur(3px)", height:"8em", position: "absolute", right: "-100px", bottom: "-50px", zIndex:"0" }}/>
<img src="../img/img_llego_nube3.png"  alt="img_llego_nube3.png" style={{filter: "blur(5px)", height:"8em", position: "absolute", right: "150px", top: "-55px", zIndex:"0" }}/>

</div>
  );
};

export default SectionPrincipal;

