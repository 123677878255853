import "./index.css";

const BannerOpenAccount = () => {

  return (
    <div className="bannerOpen-container">
    <div className="bannerOpen-relative-container">
      <div className="bannerOpen-pajarito">
        <img
          src="../img/llego_pajarito_blanco.png"
          alt="llego_pajarito_blanco.png"
          className="bannerOpen-pajarito-img"
        />
      </div>
  
      <div className="bannerOpen-nube2">
        <img
          src="../img/img_llego_nube2.png"
          alt="img_llego_nube2.png"
          className="bannerOpen-nube2-img"
        />
      </div>
  
      <a href="/registrar" className="btn btn-third-llego bannerOpen-custom-button">
        Abre tu cuenta aquí
      </a>
  
      <div className="bannerOpen-casita">
        <img
          src="../img/Llego_Casita.png"
          alt="Llego_Casita.png"
          className="bannerOpen-casita-img"
        />
      </div>
      <div className="bannerOpen-nube1">
        <img
          src="../img/img_llego_nube1.png"
          alt="img_llego_nube1.png"
          className="bannerOpen-nube1-img"
        />
      </div>
    </div>
  </div>
  
  );
};

export default BannerOpenAccount;

