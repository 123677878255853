import React, { useEffect, useState } from 'react';
import {getClientDomain, translate} from '../../../utils/Common';
import Collapse from 'react-bootstrap/Collapse';
import ReactPlayer from "react-player"
import { FaPlus } from "react-icons/fa";
import { IoRemoveOutline } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";



import './index.css';
import { useSystem } from '../../../hooks/useSystem';

const Question = ({  question, answers, youtubeId, isOpen, toggle }) => {

    return <div className="col-12 col-md-12 p-2">
                <div className="question-container">
                    <div className="d-flex justify-content-between ml-1 questions" onClick={toggle} >
                        <span  
                            className="mb-1 font-bold"
                            aria-expanded={isOpen} 
                            aria-controls="collapseQuestion1"
                            style={{color:"black"}} >
                            { question }
                        </span>
                        <div >
                            {!isOpen ? <IoIosArrowDown className="question-icon" size={20}/> : <IoIosArrowUp className="question-icon" size={20}/>}
                        </div>
                    </div>
                    <Collapse  in={isOpen}>
                        <div id="collapseQuestion1" className="text-left my-1 ml-1">
                          
                            { answers?.map((answer, index) => <p className="m-0 p-0 mb-2" key={ index }>{ answer }</p>) }
                            { youtubeId && 
                                <div className="mb-2 video-responsive">
                                    <ReactPlayer 
                                        url={`https://www.youtube.com/watch?v=${ youtubeId }`}
                                        width="100%"
                                        height="100%" />
                                </div> 
                            }
                        </div>
                    </Collapse>
                </div>
            </div>
}

const TerminosYCondiciones = () => {
    const [openTerminos, setOpenTerminos] = useState(false);
    const { getTerminosCondiciones } = useSystem(); 

    return (
        /* Terminos y condiciones */
        <div className="col-12 col-md-12 p-2">
        <div className="p-2 question-container">
            <div className="d-flex justify-content-between ml-1">
                <span onClick={ () => setOpenTerminos(!openTerminos) } 
                    className="questions mb-1 font-bold"
                    aria-expanded={openTerminos} 
                    aria-controls="collapseTerminos" >
                    {translate("dashboard.faqs.termsCondition.pdf.description")}
                </span>

                <div>
                            {!openTerminos ? <FaPlus className="question-icon" /> : <IoRemoveOutline className="question-icon" />}
                </div>
            </div>
            <Collapse in={openTerminos}>
                <div id="collapseTerminos" className="text-left my-1 ml-1">
                    <p className="m-0 p-0 mb-2" >{translate("dashboard.faqs.termsCondition.pdf.access")}:</p>
                    <p className="m-0 p-0 mb-2 questions" onClick={ () => getTerminosCondiciones() } style={{color:"#424996"}}>{translate("global.termsConditions")}</p>
                </div>
            </Collapse>
        </div>
    </div>
    );
}

const Faqs = () => {
    const itemsPerPage = 5;
    const serverQuestions = [
        {
          question: translate("landing.faqs.faq1.question"),
          answers: [translate("landing.faqs.faq1.answer")]
        },
        {
          question: translate("landing.faqs.faq2.question") ,
          answers: [
            translate("landing.faqs.faq2.answer1"),
            translate("landing.faqs.faq2.answer2"),
            translate("landing.faqs.faq2.answer3"),
            translate("landing.faqs.faq2.answer4")
          ]
        },
        {
          question: translate("landing.faqs.faq3.question"),
          answers: [translate("landing.faqs.faq3.answer1")]
        },
        {
          question: translate("landing.faqs.faq4.question"),
          answers: [translate("landing.faqs.faq4.answer1")]
        },
        {
          question:  translate("landing.faqs.faq5.question"),
          answers: [translate("landing.faqs.faq5.answer1"), translate("landing.faqs.faq5.answer2")]
        },
        {
          question: translate("landing.faqs.faq6.question"),
          answers: [translate("landing.faqs.faq6.answer1")]
        },
        {
          question: translate("landing.faqs.faq7.question"),
          answers: [translate("landing.faqs.faq7.answer1"), translate("landing.faqs.faq7.answer2")]
        },
        {
          question: translate("landing.faqs.faq8.question"),
          answers: [translate("landing.faqs.faq8.answer1"), translate("landing.faqs.faq8.answer2")]
        },
        {
          question: translate("landing.faqs.faq9.question"),
          answers: [translate("landing.faqs.faq9.answer1"), translate("landing.faqs.faq9.answer2")]
        },
        {
          question: translate("landing.faqs.faq10.question"),
          answers: [translate("landing.faqs.faq10.answer1"), translate("landing.faqs.faq10.answer2"),translate("landing.faqs.faq10.answer3")]
        },
        {
          question: translate("landing.faqs.faq11.question"),
          answers: [translate("landing.faqs.faq11.answer1")]
        },
        {
          question:  translate("landing.faqs.faq12.question") ,
          answers: [translate("landing.faqs.faq12.answer1")]
        }
      ];

    const [currentPage, setCurrentPage] = useState(1);
    const [openQuestions, setOpenQuestions] = useState(Array(itemsPerPage).fill(false));

    const totalPages = Math.ceil(serverQuestions.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentQuestions = serverQuestions.slice(indexOfFirstItem, indexOfLastItem);

    const goToPreviousPage = () => {
        setCurrentPage(prevState => prevState === 1 ? prevState : prevState - 1);
        setOpenQuestions(Array(itemsPerPage).fill(false));
    }

    const goToNextPage = () => {
        setCurrentPage(prevState => prevState === totalPages ? prevState : prevState + 1);
        setOpenQuestions(Array(itemsPerPage).fill(false));
    }

    const toggleQuestion = index => {
      setOpenQuestions(prevState => {
          const newState = [...prevState];
          newState[index] = !newState[index];
          return newState;
      });
  };

    return (
        <div id='section-questions-frecuents'>
            <div className="faqs-container m-8">
                <div className='text-center mt-4 mb-4' style={{ borderBottom: "1px dashed #dae1f5" }}>
                    <h4 className="faqs-title">{translate("dashboard.faqs.title")}</h4>
                    <p className="text-center">
                        { translate("dashboard.faqs.question")}
                    </p>

                    <div className="pagination-faqs mb-3" >
                    <div onClick={goToPreviousPage} disabled={currentPage === 1} className='btn-pagination'> {"<"} </div>
                    <span className='text-bold'>{`${currentPage} de ${totalPages}`}</span>
                    <div onClick={goToNextPage} disabled={currentPage === totalPages} className='btn-pagination'> {">"} </div>
                </div>
                </div>
                <div className="faqs-content">
                    <div className="row m-2">
                    {currentQuestions.map((faq, index) => (
                            <Question key={index} question={faq.question} answers={faq.answers.map(answer => <span className='container-html-faqs' dangerouslySetInnerHTML={{ __html: answer }} />)} youtubeId={faq?.youtubeId}  isOpen={openQuestions[index]} toggle={() => toggleQuestion(index)}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faqs;