import React, { useState, useContext } from "react";
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from "../../../components/Spinner";
import { checkConnectionStatus } from "../../../utils/Common";
// auth
import AuthContext from "../../../contexts/auth";
import SidebarContext from "../../../contexts/sidebar";
import packageJson from '../../../../package.json';
import "./index.css";
import "../index.css";
import {translate} from "../../../utils/Common";
import TraductorList from "../../../components/Traductor";
import { BiArrowBack } from "react-icons/bi";

const SignIn = () => {
	const userContext = useContext(AuthContext);
	const sidebarContext = useContext(SidebarContext)
	const history = useHistory();
	const { register, handleSubmit, errors, reset, setError } = useForm();
	const [isServerRun,setIsServerRun] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [message,setMessage] = useState();

	const onSubmit = async (data) => {
		setMessage('');
		setIsServerRun(false);
		setIsLoading(true);
		const result = await userContext.signIn(data);
		setIsLoading(false);
		
		switch (result) {
		case 'LOGIN_SUCCESS':
			reset();
			sidebarContext.handleChangeCurrentPage(translate("sidebar.options.start"));
			history.push('/dashboard');
			break;
		case 'PASSWORD_INVALID':
			setError('pwd', { type: '', message: translate("login.signin.validate.passIncorrect") });
			setIsServerRun(true);
			break;
		case 'USER_NOT_FOUND':
			setError('username', { type: '', message: translate("login.signin.validate.userNotFound") });
			setIsServerRun(true);
			break;
		case 'USER_INACTIVATED':
			setError('username', { type: '', message: translate("login.signin.validate.userInactive") });
			setIsServerRun(true);
			break;
		case 'USER_NOT_VERIFIED':
			setError('username', { type: '', message: translate("login.signin.validate.userNotVerified") });
			setIsServerRun(true);
			break;
		case 'SERVER_ERROR':
			if (checkConnectionStatus()) {
				setMessage(translate("login.signin.validate.errorConnectionTrue"));
			}
			else {
				setMessage(translate("login.signin.validate.errorConnectionFalse"));
			}
			setIsServerRun(false);
			break;
		
		default:
			break;
		}
	};

	return (
		<div className="login d-flex align-items-center justify-content-center" style={{
			backgroundImage: "url('/img/online_shopping.jpg')",
			backgroundSize: "cover"
		  }}>
			<div className="position-fixed fixed-top p-4 d-flex flex-row-reverse">
					<TraductorList />
			</div>
			<form className="signin-form  shadow" onSubmit={handleSubmit(onSubmit)}>
				<div className="d-flex justify-content-end mb-0">
					{/* <p style={{fontSize:"16px", color:"#000000", fontWeight:"400"}}>Bienvenido a Llegó</p> */}
					<div className="text-right">
						<p className="mb-0" style={{color:"#8D8D8D"}}>{translate("global.dontHaveAccount")}</p>
						<Link to="/registrar" style={{color:"#706EDB"}} className="link font-weight-bold mb-0">{translate("login.signin.registerHere")}</Link>
					</div>
				</div>

		  		<div className="d-flex align-items-center">
				  <img src="../img/LLEGO_logo.png" className="mb-3" alt="Boxit" height="75"/>
				  <h6 className="pl-3" style={{fontSize:"30px", color:"black"}}>{translate("global.signIn")}</h6>
				</div>

				<div className="form-group mb-4">
					<p className="mb-1" style={{color:"black"}}>{translate("global.enterEmail")}</p>
					<input disabled={ !isServerRun } type="email"  className={ "input-signin form-control form-control-lg" + (errors.username ? " is-invalid" : "") } name="username" placeholder={translate("global.email")} required autoFocus
					 ref={register({
						required: {
							value: true,
							message: translate("login.signin.validate.required")
						}
					})} />
					{errors.username && <div className="invalid-feedback">{errors.username.message}</div>}
				</div>

				<div className="form-group mb-2">
					
					<p className="mb-1"  style={{color:"black"}}>{translate("global.enterPassword")}</p>
					<input disabled={ !isServerRun } type="password" className={ "input-signin form-control form-control-lg" + (errors.pwd ? " is-invalid" : "")} name="pwd" placeholder={translate("login.signin.password")} required ref={register({
						minLength: {
						value: 6,
						message:  translate("login.resetpwd.validate.minLength")
						},
						maxLength: {
						value: 16,
						message: translate("login.resetpwd.validate.maxLength")
						}
					})}/>
					{errors.pwd && <div className="invalid-feedback">{errors.pwd.message}</div>}
				</div>

				<div className="mb-4 d-flex justify-content-end">
					<Link to="/forgotpwd" className="link font-small" style={{color:"#706EDB"}}>{translate("login.signin.forgot")}</Link>
				</div>

				<button disabled={ !isServerRun } className="btn btn-primary-llego btn-lg" style={{width:"100%"}} type="submit">
					{ !isLoading ? translate("login.signin.button") : <Spinner type={1} size={18} /> }
				</button>

				<div className="text-center mt-3">
					<p className="text-muted font-xsmall mb-0 mt-1">v{packageJson.version}</p>
				</div>

				<div className="text-center mt-3">
					<a href="/" className="font-xsmall mb-0 mt-1"><BiArrowBack className="mr-2" />{translate("global.backToHome")}</a>
				</div>

				{ message && <div className="text-center mt-3"><span className="text-danger font-xsmall">{message}</span></div> }
			</form>
		</div>
	);
};

export default SignIn;
