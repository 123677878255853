import React, { useState } from "react";
import "./index.css";
// components
import SignUpSuccess from "./SignUpSuccess";
import FormSignUpSteps from "./FormSignUpSteps";
import { translate } from "../../../utils/Common";
import TraductorList from "../../../components/Traductor";
import CountryList from "../../../components/Country";

const Signup = () => {
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);

  const onSignupSuccess = (value) => {
    setIsSignupSuccess(value);
  };

  return (
    <div className="signUp d-flex align-items-center justify-content-center"   style={{
      backgroundImage: "url('/img/online_shopping.jpg')",
			backgroundSize: "cover"
    }}>
    
      <div className="signup-form text-center">
        
      <div className="d-flex align-items-center">
				  <img src="../img/LLEGO_logo.png" className="mb-3" alt="Boxit" height="75"/>
				  <h6 className="pl-3" style={{fontSize:"30px", color:"black"}}>{translate("global.signUp")}</h6>
				</div>

        
        {isSignupSuccess ? (
          <SignUpSuccess onSignupSuccess={onSignupSuccess} />
        ) : (
          <FormSignUpSteps onSignupSuccess={onSignupSuccess} />
        )}
      </div>
    </div>
  );
};

export default Signup;
