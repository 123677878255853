import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import SidebarContext from '../../../contexts/sidebar';
import './index.css';

const Submenu = (props) => {
    const [subnav, setSubnav] = useState(false);
    const sidebarContext = useContext(SidebarContext);

    const handleMenuOption = () => {
        if (props.item.subNav) setSubnav(!subnav);

        if (sidebarContext.isMobile && sidebarContext.sidebarOpen && !props.item.subNav) sidebarContext.closeSidebar();
            
        sidebarContext.handleChangeCurrentPage(props.item.title);
    }

    const handleSubmenuOption = () => {
        if (!sidebarContext.sidebarOpen) setSubnav(!subnav);

        if (sidebarContext.isMobile && sidebarContext.sidebarOpen)
        {
            setSubnav(!subnav);
            sidebarContext.closeSidebar();
        }
    }

    useEffect(() => {
        if (sidebarContext.sidebarOpen === false) setSubnav(false);
    }, [sidebarContext.sidebarOpen]);

    return(
        <>
            {
                <Link className={ "sidebarLink" + (props.item.title === sidebarContext.currentPage ? " current-page" : "") } to={props.item.path} onClick={ () => handleMenuOption() }>
                    <div className='sidebarLinkContent'>
                        <div>{props.item.icon}</div>
                        <span className={"sidebarLabel mt-2 text-center"}>{props.item.title}</span>
                    </div>
                </Link>
            }            

            <ul className={ sidebarContext.sidebarOpen ? "d-flex flex-column" : "submenu-ul"}>
            {
                subnav && 
                    (props.item.title === sidebarContext.currentPage ? props.item.subNav.map((item, index) => {
                        return <li key={index}>
                                    <Link className="dropdownLink" to={item.path} onClick={ () => handleSubmenuOption() } >
                                        <span className="sidebarLabel" >{item.title}</span>
                                    </Link>
                                </li>
                    }) 
                    : 
                    setSubnav(false)
                )
            }
            </ul>
        </>
    );
}

export default Submenu;