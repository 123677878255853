import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { searchCountryByCodePhone } from "./codesCountries";
import { translate } from "../../utils/Common";

const PhoneInputCustome = (props) => {
  
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChangePhone = (value, country, data, formattedValue) => {
    
    const phoneNumberParts = formattedValue.split(" ");
    const actualNumber = phoneNumberParts.slice(1).join("");
    const completeNumber = "+"+country.dialCode+" "+actualNumber;

    setPhoneNumber(completeNumber);
    props.setValidatePhone(completeNumber);
  };



  return (
    <div>
       <PhoneInput
            specialLabel={''}
            country={props?.country || 'pa'}
            enableSearch
            disableSearchIcon={false}
            onChange={handleChangePhone}
            disabled={props.disabled}
            countryCodeEditable={false}
            inputProps={{
              name: "phoneNumber"
            }}
            value={props.defaultValue}
            inputStyle={{background:props.disabled ? "#e9ecef" : "#fff", width: "100%"}}
            searchPlaceholder={translate("global.search")}
            searchNotFound={translate("global.noResults")}
            />
    </div>
  );
};

export default PhoneInputCustome;
