import React, { useState, useRef, useContext } from 'react';
import AuthContext from '../../../contexts/auth';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
// icons
import * as FiIcons from 'react-icons/fi';
// components
import Spinner from '../../../components/Spinner';
import {translate} from "../../../utils/Common";

const FormResetPwd = ({ setResetPwdSuccess, id }) => {    
    const userContext = useContext(AuthContext);
    const { handleSubmit, reset, register, errors, setError, watch } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isPwdVisible, setIsPwdVisible] = useState(false);
    const [isPwdConfirmVisible, setIsPwdConfirmVisible] = useState(false);
    const pwd = useRef({});
    pwd.current = watch("pwd","");
    // para validar números, letras y caracteres especiales
    const regexLetras = /[a-zA-Z]+/;
    const regexNum = /[0-9]+/;
    const regexChar = /["#$%&/()=¿?¡!_.,+*']+/;

    const sendNewPwd = async (data) => {
        try {
            setIsLoading(true);
            const result = await userContext.resetPwd(data, id);
            setIsLoading(false);
            
            switch (result) {
                case 'PWD_RESTABLISHED_SUCCESSFULLY':
                    reset();
                    setResetPwdSuccess(true);
                    break;
                
                    
                case 'TOKEN_EXPIRED':
                    reset();
                    setError('pwd', { type: '', message: translate("login.resetpwd.validate.tokenExpired") });
                    break;
                
                default:
                    setError('pwd', { type: '', message: translate("login.resetpwd.validate.defaultError") });
                    break;
            }
        } catch (error) {
            setError('pwd', { type: '', message: translate("login.resetpwd.validate.catchError") });
        }
    }

    return (
        <form className='text-center' onSubmit={ handleSubmit(sendNewPwd) }>
            <div className="title">
                <h5 className="mb-2">{translate("login.resetpwd.title")}</h5>
                <p className="mb-2">{translate("login.resetpwd.instructions")}</p>
            </div>

            <div className="form-row text-left mb-2 px-3">
                <label htmlFor="inputPwd" className="mb-1"><span className="text-danger">*</span> {translate("login.resetpwd.password")}:</label>
                <div className="input-group">
                    <div className="input-group-prepend" onClick={ () => setIsPwdVisible(!isPwdVisible) }>
                        <span className="input-group-text" id="span-eye-pwd">
                            { !isPwdVisible ?
                                <FiIcons.FiEye size={16} className="text-dark"/>
                                :
                                <FiIcons.FiEyeOff size={16} className="text-dark"/>   
                            }
                        </span>
                    </div>
                    <input type={ isPwdVisible ? "text" : "password" } maxLength={15} className={ "form-control form-control-md " + (errors.pwd ? "is-invalid" : "") } id="inputPwd" name="pwd" required ref={register({
                        minLength: {
                            value: 6,
                            message: translate("login.resetpwd.validate.minLength")
                        },
                        maxLength: {
                            value: 16,
                            message: translate("login.resetpwd.validate.maxLength")
                        },
                        validate: value => (regexLetras.test(value) && regexNum.test(value) && regexChar.test(value)) || 'La contraseña no cumple con los parámetros solicitados',
                    })}/>
                    {errors.pwd && <div className="invalid-feedback">{errors.pwd.message}</div> }
                </div>
            </div>

            <div className="form-row text-left mb-2 px-3">
                <label htmlFor="pwdConfirm" className="mb-1"><span className="text-danger">*</span> {translate("login.resetpwd.confirm")}:</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="span-eye-pwdConfirm" onClick={ () => setIsPwdConfirmVisible(!isPwdConfirmVisible) }>
                            { !isPwdConfirmVisible ?
                                <FiIcons.FiEye size={16} className="text-dark"/>
                                :
                                <FiIcons.FiEyeOff size={16} className="text-dark"/>   
                            }
                        </span>
                    </div>
                    <input type={ isPwdConfirmVisible ? "text" : "password" } maxLength={15} className={ "form-control form-control-md " + (errors.pwdConfirm ? "is-invalid" : "") } id="pwdConfirm" name="pwdConfirm" required ref={register({
                        minLength: {
                            value: 6,
                            message: translate("login.resetpwd.validate.minLength")
                        },
                        maxLength: {
                            value: 16,
                            message: translate("login.resetpwd.validate.maxLength")
                        },
                        validate: value => value === pwd.current || translate("login.resetpwd.validate.passNotMatch") 
                    })}/>
                    {errors.pwdConfirm && <div className="invalid-feedback">{errors.pwdConfirm.message}</div> }
                </div>
            </div>

            <div className="mb-2 text-left px-3">
                <p className="font-xsmall m-0 p-0">{translate("login.resetpwd.validate1")}:</p>
                <p className="font-xsmall m-0 p-0">- {translate("login.resetpwd.validate2")}</p>
                <p className="font-xsmall m-0 p-0">- {translate("login.resetpwd.validate3")}</p>
                <p className="font-xsmall m-0 p-0">- {translate("login.resetpwd.validate4")}</p>
            </div>

            <div className="form-group mt-2 mb-0">
                <button className="btn btn-primary-llego btn-reset rounded-pill btn-lg text-uppercase m-2" type="submit">
                    { !isLoading ? translate("login.resetpwd.buttonAccept") : <Spinner type={1} size={18} /> }
                </button>
                <Link to='/login'><button className="btn btn-secondary-llego btn-reset rounded-pill btn-lg text-uppercase m-2">{translate("login.resetpwd.buttonReturn")}</button></Link>
            </div>
        </form>
    );
}

export default FormResetPwd;