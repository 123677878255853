import { useState, useEffect } from "react";
import { usePlatforms } from "../hooks/usePlatforms";
import { translate } from "../utils/Common";
import { FaLocationDot } from "react-icons/fa6";


const PlatformList = ({
  register,
  errors,
  nameShow,
  idShow,
  readOnly,
  idPlatform,
  sizeInput,
}) => {
  const { platforms, getAllPlatforms } = usePlatforms();
  const [plataformasList, setPlataformasList] = useState([]);

  const [plataformaSeleccionada, setPlataformaSeleccionada] = useState(idPlatform);

  useEffect(() => {
    const abortController = new AbortController();

    const handlePlatforms = async () => {
      const platforms = await getAllPlatforms(abortController.signal);
      setPlataformasList(platforms);
    };

    if (!platforms) handlePlatforms();
    else setPlataformasList(platforms);
    
    return () => abortController.abort();
  }, [platforms, getAllPlatforms]);


  const handlePlataformaChange = (event) => {
    setPlataformaSeleccionada(parseInt(event.target.value)); 
  };

  const addressPlatformSelected = plataformasList.find(plataforma => plataforma.boxitLocationId === plataformaSeleccionada)?.locationAddress;
  const addressLocationSelected = plataformasList.find(plataforma => plataforma.boxitLocationId === plataformaSeleccionada)?.BoxitUbicacion;

  return (
    <>
      <select
        className={`${sizeInput ?? "form-control-sm"} form-control `}
        disabled={readOnly}
        name="plataforma"
        value={plataformaSeleccionada}
        onChange={handlePlataformaChange}
        ref={register}
      >
        {plataformasList.length > 0 ? (
          plataformasList.map((boxit) => (
            <option
              key={boxit.boxitLocationId}
              className="font-small"
              value={boxit.boxitLocationId}
            >
              {boxit.locationName}
            </option>
          ))
        ) : (
          <option defaultValue className="font-small">
            {translate("global.noPlatforms")}
          </option>
        )}
      </select>
      {errors.plataforma && (
        <span className="text-error">{errors.plataforma.message}</span>
      )}

{addressPlatformSelected && (
        <div className="bd-callout bd-callout-warning p-4 mt-3" style={{background: "black", color: "white", borderRadius: "13px", gap: "20px"}}>
          <FaLocationDot style={{ color: "#F9ED32" }} className="mr-2" />
          {addressPlatformSelected}
          {addressLocationSelected && (
                    <a href={addressLocationSelected ?? ""} target="_blank"  rel="noreferrer" className="ml-2" style={{ color: "#F9ED32" }}>
                     <u>{translate("global.viewMap")}</u>
                  </a>
                  )}
        </div>
      )}
    </>
  );
};

export default PlatformList;
