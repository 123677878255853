import { useState, useRef, useContext, useEffect } from "react";
import AuthContext from "../../../contexts/auth";
import Spinner from "../../../components/Spinner";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiCheckCircle, BiCircle, BiUser, BiXCircle } from "react-icons/bi";
import { BsFillBriefcaseFill, BsBuilding } from "react-icons/bs";
import { getClientDomain } from "../../../utils/Common";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// icons
import * as MdIcons from "react-icons/md";
import { translate } from "../../../utils/Common";
import PhoneInputCustome from "../../../components/PhoneInput";
import PlatformPublicList from "../../../components/PlatformPublicList";
import TraductorList from "../../../components/Traductor";
import DatePickerBirth from "../../../components/DatePickerBirth";
import { useSystem } from "../../../hooks/useSystem";

const FormSignUpSteps = ({ onSignupSuccess }) => {
  const userContext = useContext(AuthContext);
  const { register, handleSubmit, setError, errors, reset, watch } = useForm();
  const [message, setMessage] = useState();
  const [isServerRun, setIsServerRun] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [validatePhone, setValidatePhone] = useState("");
  const [formDateBirth,setFormDateBirth] = useState("");
  const [formDateBirthError,setFormDateBirthError] = useState(false);

  const history = useHistory();
  const pwd = useRef({});
  pwd.current = watch("pwd", "");
  // para validar números, letras y caracteres especiales
  const regexNum = new RegExp("(?=.*[0-9])");
  const regexChar = new RegExp(
    /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿¢£€¥©®™µ°ªº±÷¬§¶¼½¾°F°C≠≤≥≈∑∫∆√πΩ≡≈≠†‡↔↑↓←→∞∩∂∇]+/
  );
  const regexLetras = new RegExp("(?=.{6,})");

  const onSubmit = async (data) => {
    try {
      setMessage("");
      setFormDateBirthError(false)
      setIsServerRun(false);
      setIsLoading(true);
      data.membershipId = plan;
      const result = await userContext.signUp({...data, "birthDate": formDateBirth, "phoneNumber": validatePhone});
      console.log("RESULT SIGNUP", result)
      setIsLoading(false);

      switch (result) {
        case "SIGNUP_SUCCESSFULLY":
          reset();
          onSignupSuccess(true);
          setIsServerRun(true);
          break;
        case "USER_OR_EMAIL_ALREADY_EXIST":
          setError("username", {
            type: "",
            message: translate("login.signup.validate.userExist"),
          });
          setIsServerRun(true);
          break;
        case "EMAIL_ALREADY_EXIST":
          setError("email", {
            type: "",
            message: translate("login.signup.validate.emailExist"),
          });
          setIsServerRun(true);
          break;
        case "INVALID_AGE_REGISTER":
          setFormDateBirthError(true)
          setIsServerRun(true);
          handlePreviousStep();          
          break;
        case "SERVER_ERROR":
          setMessage(translate("login.signup.validate.serverError"));
          setIsServerRun(false);
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
      setIsServerRun(false);
    }
  };

  const backLogin = () => history.push("/login");

  // Constants
  const INITIAL_STEP = 2;
  const TOTAL_STEPS = 3;
  const [step, setStep] = useState(INITIAL_STEP);

  // Funciones para avanzar y retroceder entre pasos
  const handleNextStep = () => {
    const nextStep = Math.min(step + 1, TOTAL_STEPS);
    setStep(nextStep);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, INITIAL_STEP));
  };

  const [plan, setPlan] = useState(3);
  const { getTerminosCondiciones } = useSystem(); 

  const verificarCamposVacios = (datos) => {
    for (let campo in datos) {
        if (datos[campo] === "") {
            return false;
        }
    }
    return true;
}

  const checkFormCompletion = () => {
    if (plan === 3) {
      const requiredFields = ["name", "lastname"];
      const objFields = {...watch(requiredFields), phoneNumber: validatePhone, birthDate : formDateBirth};
      const isComplete = verificarCamposVacios(objFields);
      setIsFormComplete(isComplete);
    } 
  };

  useEffect(() => {
    checkFormCompletion();
  }, [watch]);

  const [charactersValidated, setCharactersValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);

  const handlePasswordChange = (e) => {
    regexLetras.test(e)
      ? setCharactersValidated(true)
      : setCharactersValidated(false);
    regexNum.test(e) ? setNumberValidated(true) : setNumberValidated(false);
    regexChar.test(e) ? setSpecialValidated(true) : setSpecialValidated(false);
  };

  const [isFocused, setIsFocused] = useState(false);
  const [showValidatePwd, setshowValidatePwd] = useState(false);
  const renderValidatePwd = (
    <Tooltip id={`tooltip-${"bottom"}`}>
      <div className="mb-2 text-left">
        <p className="font-small m-0 p-0 font-bold">
          {translate("login.signup.validate1")}
        </p>
        <p
          className="font-small m-0 p-0"
          style={{ color: charactersValidated ? "green" : "gray" }}
        >
          <span className="mr-1">
            {charactersValidated ? <BiCheckCircle /> : <BiCircle />}{" "}
          </span>
          {translate("login.signup.validate2")}
        </p>
        <p
          className="font-small m-0 p-0"
          style={{ color: numberValidated ? "green" : "gray" }}
        >
          <span className="mr-1">
            {numberValidated ? <BiCheckCircle /> : <BiCircle />}{" "}
          </span>
          {translate("login.signup.validate3")}
        </p>
        <p
          className="font-small m-0 p-0"
          style={{ color: specialValidated ? "green" : "gray" }}
        >
          <span className="mr-1">
            {specialValidated ? <BiCheckCircle /> : <BiCircle />}{" "}
          </span>
          {translate("login.signup.validate4")}
        </p>
      </div>
    </Tooltip>
  );

  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    const originalPassword = watch("pwd");
    setPasswordsMatch(confirmPassword === originalPassword);
  };

  const handleConfirmPasswordFocus = () => {
    setIsConfirmPasswordFocused(true);
  };

  const handleConfirmPasswordBlur = () => {
    setIsConfirmPasswordFocused(false);
  };

  const renderConfirmPwd = (
    <Tooltip id="tooltip-confirm-password">
     <p
        className="font-small m-0 p-0"
        style={{ color: passwordsMatch ? "green" : "red" }}>
        <span className="mr-1">
          {passwordsMatch ? <BiCheckCircle /> : <BiXCircle />}{" "}
        </span>
        {passwordsMatch ? translate("login.signup.validate.passMatch") : translate("login.signup.validate.passNotMatch")}
      </p>
  </Tooltip>
  );

  return (
    <>
  
      <div className="m-0 p-0">
      <div className="position-fixed fixed-top p-4 d-flex flex-row-reverse">
					<TraductorList />
			</div>
        {/* <h5 className="mb-2">{translate("login.signup.title")}</h5> */}
        <p className="mb-1 text-justify">
          {step === 1 && translate("login.signup.instructions.greeting")}
          <span style={{marginRight:"10px"}}>{translate("global.registerMembershipFamily")} </span>
          {translate("login.signup.instructions.form")} 
          <span className="ml-2 mb-2 text-left text-danger">
            (*) {translate("login.signup.required")}
          </span>
        </p>
        <p className="text-muted text-left">
        {translate("global.registerMembershipFamilySuccess")}
        </p>
      </div>

      {/* STEPPER */}
      {/* Steps - Head */}
      <div className="step-head">
        <div className="stepper-container">
          {[2, 3].map((stepNumber) => (
            <div
              key={stepNumber}
              className={`step-circle ${step === stepNumber ? "active" : ""}`}
            >
              <p
                className={`step-title ${step === stepNumber ? "active" : ""}`}
              >
                {stepNumber === 2
                  ? translate("login.signup.steps.data")
                  : translate("login.signup.steps.access")}
              </p>
            </div>
          ))}
        </div>

        <div className="progress-container">
          <div className="progress" style={{ height: "2px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(step - 1) * 50}%` }}
              aria-valuenow={(step - 1) * 50}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
      </div>
      {/* Steps - Head */}

      <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
 
        {/* DATA STEP */}
        <div
          className="step-data"
          style={{ display: step === 2 ? "block" : "none" }}
        >
       
            <>
              <div className="form-row">
                <div className="form-group text-left col-md-6">
                  <label htmlFor="inputName" className="mb-1">
                    <span className="text-danger">*</span>{" "}
                    {translate("login.signup.contact")}
                  </label>
                  <input
                    disabled={!isServerRun}
                    type="text"
                    className={
                      "form-control form-control-md " +
                      (errors.name && " is-invalid")
                    }
                    id="inputName"
                    name="name"
                    ref={register({
                      required: true,
                      maxLength: {
                        value: 32,
                        message: translate("login.signup.validate.maxLength32"),
                      },
                    })}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">
                      {errors.name.message}
                    </div>
                  )}
                </div>

                <div className="form-group text-left col-md-6">
                  <label htmlFor="inputLastname" className="mb-1">
                    <span className="text-danger">*</span>{" "}
                    {translate("login.signup.lastname")}
                  </label>
                  <input
                    disabled={!isServerRun}
                    type="text"
                    className={
                      "form-control form-control-md " +
                      (errors.lastname && "is-invalid")
                    }
                    id="inputLastname"
                    name="lastname"
                    ref={register({
                      required: true,
                      maxLength: {
                        value: 32,
                        message: translate("login.signup.validate.maxLength32"),
                      },
                    })}
                  />
                  {errors.lastname && (
                    <div className="invalid-feedback">
                      {errors.lastname.message}
                    </div>
                  )}
                </div>

                <div className="form-group text-left col-md-12">
                  <label htmlFor="inputBirthDate" className="mb-1">
                  <span className="text-danger">*</span>{" "}
                    {translate("dashboard.userprofile.personal.birthdate_label")}

                  </label>
                  {/* <input
                    disabled={!isServerRun}
                    type="date"
                    className={
                      "form-control form-control-md " +
                      (errors.birthDate && " is-invalid")
                    }
                    id="inputBirthDate"
                    name="birthDate"
                    ref={register}
                  /> */}
                  
                  <DatePickerBirth register={register} setFormDateBirth={setFormDateBirth} className="form-control-md"/>
                  {formDateBirthError && (
                    <div className="mt-2" style={{color:"red"}}>
                      {translate("login.signup.validate.age")}
                    </div>
                  )}
                </div>

              </div>
            </>


          <div className="form-row">
            <div className="form-group text-left col-md-12">
              <label htmlFor="inputPhone" className="mb-1">
              <span className="text-danger mr-1">*</span>
                {translate("global.phone")}
              </label>
              <div className="input-group">

                <PhoneInputCustome country={'pa'} disabled={!isServerRun} setValidatePhone={setValidatePhone}/>
        
                {errors.phoneNumber && (
                  <div className="invalid-feedback">
                    {errors.phoneNumber.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-row">
  <div className="form-group text-left col-md-12">
    <label htmlFor="inputPhone" className="mb-1">
      {translate("global.gender")}
    </label>
    <div className="input-group">
      <select
        disabled={!isServerRun}
        className={
          "form-control form-control-SM " +
          (errors.genderType && "is-invalid")
        }
        id="inputGenderType"
        name="genderType"
        ref={register}
      >
        <option value="">{translate("global.selectGender")}</option>
                  <option value="H">{translate("global.male")}</option>
                  <option value="M">{translate("global.female")}</option>
                  <option value="">{translate("global.n/a")}</option>
      </select>
      {errors.genderType && (
        <div className="invalid-feedback">
          {errors.genderType.message}
        </div>
      )}
    </div>
  </div>
</div>
        </div>

        {/* ACCESS STEP */}
        <div
          className="step-access"
          style={{ display: step === 3 ? "block" : "none" }}
        >
          <div className="form-group text-left">
            <label htmlFor="inputEmail" className="mb-1">
              <span className="text-danger">*</span>{" "}
              {translate("login.signup.email")}
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputEmail">
                  <MdIcons.MdEmail size={18} className="text-dark" />
                </span>
              </div>
              <input
                disabled={!isServerRun}
                type="email"
                className={
                  "form-control form-control-md " +
                  (errors.email && "is-invalid")
                }
                id="inputEmail"
                name="email"
                ref={register({ required: true })}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </div>
          
          </div>
          <div className="form-row">
            <div className="form-group text-left col-12 col-md-6 mb-1">
              <label htmlFor="inputPwd" className="mb-1">
                <span className="text-danger">*</span>{" "}
                {translate("login.signup.password")}:
              </label>
              <OverlayTrigger
                show={showValidatePwd && isFocused}
                placement={"bottom"}
                overlay={renderValidatePwd}
              >
                <input
                  disabled={!isServerRun}
                  maxLength={15}
                  type="password"
                  className={
                    "form-control form-control-md " +
                    (errors.pwd && " is-invalid")
                  }
                  id="inputPwd"
                  name="pwd"
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  onFocus={() => {
                    setIsFocused(true);
                    setshowValidatePwd(true);
                  }}
                  onBlur={() => {
                    setIsFocused(false);
                    setshowValidatePwd(false);
                  }}
                  ref={register({
                    required: true,
                    minLength: {
                      value: 6,
                      message: translate("login.signup.validate.minLength6"),
                    },
                    maxLength: {
                      value: 15,
                      message: translate("login.signup.validate.maxLength15"),
                    },
                    validate: (value) =>
                      (regexLetras.test(value) &&
                        regexNum.test(value) &&
                        regexChar.test(value)) ||
                      translate("login.signup.validate.pwd"),
                  })}
                />
              </OverlayTrigger>

              {errors.pwd && (
                <div className="invalid-feedback">{errors.pwd.message}</div>
              )}
            </div>

            <div className="form-group text-left col-12 col-md-6 mb-1 mt-2">
              <label htmlFor="inputConfirmPwd" className="mb-1">
                <span className="text-danger">*</span>{" "}
                {translate("login.signup.confirm")}
              </label>
              <OverlayTrigger
                show={isConfirmPasswordFocused}
                placement="bottom"
                overlay={renderConfirmPwd}
              >
                <input
                  disabled={!isServerRun}
                  maxLength={15}
                  type="password"
                  className={
                    "form-control form-control-md " +
                    (errors.confirmPwd && " is-invalid")
                  }
                  id="inputConfirmPwd"
                  name="confirmPwd"
                  ref={register({
                    required: true,
                    minLength: {
                      value: 6,
                      message: translate("login.signup.validate.minLength6"),
                    },
                    maxLength: {
                      value: 15,
                      message: translate("login.signup.validate.maxLength15"),
                    },
                    validate: (value) =>
                      value === pwd.current ||
                      translate("login.signup.validate.passNotMatch"),
                  })}
                  onChange={(e) => handleConfirmPasswordChange(e)}
                  onFocus={handleConfirmPasswordFocus}
                  onBlur={handleConfirmPasswordBlur}
                />
              </OverlayTrigger>

              {errors.confirmPwd && (
                <div className="invalid-feedback">
                  {errors.confirmPwd.message}
                </div>
              )}
            </div>
          </div>

          <div className="form-row mt-2">
  <div className="form-group text-left col-md-12">
    <label htmlFor="inputLockerPreference" className="mb-1">
      <span className="text-danger">*</span>{" "}
      {translate("global.lockerPreference")}
    </label>
    <div className="input-group">
      
     <PlatformPublicList
           errors={errors}
           register={register}
           nameShow={translate("global.select")}
          //  readOnly={!isEditing} 
          //  idPlatform={dataClient.IdPlataforma}
           sizeInput={"form-control-md"}
         />

      {errors.lockerPreference && (
        <div className="invalid-feedback">
          {errors.lockerPreference.message}
        </div>
      )}
    </div>
  </div>
</div>

          <div className="form-row mt-4">
            <div className="form-group text-left col-md-12 mb-1">
              <div>
                <div className="text-bold mb-1">
                  <span className="text-danger">*</span>{" "}
                  {translate("global.termsConditions")}
                </div>
                <p className="m-0 p-0 text-justify">
                  {translate("login.signup.descriptionTerms")}
                  <a href="https://public.loquepedi.com/Terminos_y_Condiciones_Llego_App.pdf" target="_blank" rel="noopener noreferrer" className="text-bold link-span ml-2" style={{color:"#424996"}}>{translate("global.termsConditions")}</a>
                  .
                </p>
              </div>
              <div className="form-check">
                <input
                  disabled={!isServerRun}
                  type="checkbox"
                  className={
                    "form-check-input " + (errors.conditions && "is-invalid")
                  }
                  id="checkConditions"
                  name="conditions"
                  required
                  ref={register}
                />
                <label htmlFor="checkConditions" className="m-0">
                  {translate("login.signup.accept")}
                </label>
                {errors.conditions && (
                  <div className="invalid-feedback">
                    {errors.conditions.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="text-center my-2 text-danger">
            {message && (
              <div className="text-center mt-3">
                <span className="text-danger font-xsmall">{message}</span>
              </div>
            )}
          </div>
        </div>

        {/* ACTIONS BUTTONS */}
        <div className="form-group mt-1 mb-0">
          {/* Buttons */}
          {step !== INITIAL_STEP ? (
            <button
              type="button"
              onClick={handlePreviousStep}
              disabled={step === INITIAL_STEP}
              className="btn btn-secondary-llego text-uppercase m-2"
            >
              {translate("login.signup.btn.previous")}
            </button>
          ) : (
            <button
              type="reset"
              onClick={() => backLogin()}
              className="btn btn-secondary-llego text-uppercase m-2"
            >
              {translate("login.signup.buttonReturn")}
            </button>
          )}

          {step !== TOTAL_STEPS ? (
            <button
              type="button"
              onClick={handleNextStep}
              disabled={step === 2 && !isFormComplete}
              className="btn btn-primary-llego btn-lg text-uppercase m-2"
            >
              {translate("login.signup.btn.next")}
            </button>
          ) : (
            <button
              disabled={!isServerRun}
              className="btn btn-primary-llego text-uppercase m-2"
              type="submit"
            >
              {!isLoading ? (
                translate("login.signup.buttonAccept")
              ) : (
                <Spinner type={1} size={18} />
              )}
            </button>
          )}
          {/* Buttons */}
        </div>
      </form>
    </>
  );
};

export default FormSignUpSteps;
