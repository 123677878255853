import React, { useState, useContext } from 'react';
import { getClDomain, translate } from '../../utils/Common';
// contexts
import AuthContext from '../../contexts/auth';
import SidebarContext from '../../contexts/sidebar';
// icons
import * as BsIcons from 'react-icons/bs';
import './index.css';
// sidebar info
import Submenu from './Submenu';
import Options from './Options/index';
import { Link } from 'react-router-dom';

const Sidebar = () => {
	const userContext = useContext(AuthContext);
	const sidebarContext = useContext(SidebarContext);
	const [SidebarData,] = useState(Options(userContext.user));

	return (
		<div 
			className={ "sidebar" + (sidebarContext.sidebarOpen ? "-open" : "-close") }
			style={ getClDomain() !== 'loquepedi.com' ? { top: "22px" } : { top: "0" } }>
			<div style={{position:"relative"}}>
			<Link
                to="/dashboard"
				className='logo-section d-flex justify-content-between'
                onClick={() => sidebarContext.handleChangeCurrentPage(translate("sidebar.options.start"))}
              >
				<div className="logo-boxit" style={{
					backgroundImage: sidebarContext.sidebarOpen ? `url(/img/logo-llego-bordes.png)` : "url(/img/logo-llego-bordes.png)",
					width: sidebarContext.sidebarOpen ? 55 : 55,
					height: sidebarContext.sidebarOpen ? 55 : 55
				 }} />
              </Link>

			  <div  style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-10px"
                }}>
			  { sidebarContext.isMobile && 
					<div className="close-icon mt-1 mr-3" onClick={ () => sidebarContext.closeSidebar() } >
						<BsIcons.BsX className="btn-close" size={ 30 }/>
					</div>
				}
			  </div>

			</div>

			<ul className="sidebarWrap d-flex flex-column">
			{
				SidebarData && SidebarData.map((item, index) => {
					return <li key={index} className="relative">
								<Submenu item={item} />
							</li>
				})
			}
			</ul>
		</div>
	);
}

export default Sidebar;