import { FiBox } from "react-icons/fi";

import "./show.css";
import { getFirstXChars, getFormatDate, getLabelEstatusCompraCliente, translate } from "../../utils/Common";
import { FaCalendarAlt, FaRegClock, FaSearchLocation } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { MdDescription, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { FaCircleCheck, FaLocationDot } from "react-icons/fa6";
import { useState } from "react";

const ItemClientsCanvas = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="item-package">

        <div className={item.IdEstatusCompra !== 4 ? "overviewInfo-borrador":"overviewInfo"}></div>

        <div className="productSpecifications">
          <div className="productFeatures">
            {item?.NumeroPaquete && (
              <div className="feature">
                <div className="featureText">
                  <div>
                    {" "}
                    <FiBox size={16} className="mr-2" color="#F9ED32" />
                    <strong> {translate("global.numPackage")}</strong>
                  </div>
                  <div className="ml-4">{item?.NumeroPaquete}</div>
                </div>
              </div>
            )}

            <div className="feature">
              <div className="featureText">
                <div>
                  {" "}
                  <FaSearchLocation
                    size={16}
                    className="mr-2"
                    color="#F9ED32"
                  />
                  <strong> {translate("global.numTracking")}</strong>
                </div>
                <div className="ml-4">{item?.NumeroTracking}</div>
              </div>
            </div>

            {
  item?.Descripcion && (
    <div className="feature">
      <div className="featureText">
        <div>
          <MdDescription size={16} className="mr-2" />
          <strong>{translate("global.description")}</strong>
        </div>
        <div className="ml-4">
          {isExpanded ? item.Descripcion : getFirstXChars(item.Descripcion, 50)}
          
          {item.Descripcion.length > 50 && (
            <span onClick={handleToggleExpand} className="ml-2 expandButton text-underline">
              
              {isExpanded ? <MdVisibilityOff size={16} className="mr-2" /> : <MdVisibility size={16} className="mr-2" />}
            </span>
          )}
          
        </div>
      </div>
    </div>
  )
}
            

            {item?.FechaEstimada && (
              <div className="feature">
                <div className="featureText">
                  <div>
                    {" "}
                    <FaCalendarAlt size={14} className="mr-2" />
                    <strong>{translate("global.estimatedDate")}</strong>
                  </div>
                  <div className="ml-4">{getFormatDate(item?.FechaEstimada)}</div>
                </div>
              </div>
            )}
            {item?.Precio && (
              <div className="feature">
                <div className="featureText">
                  <div>
                    {" "}
                    <RiMoneyDollarCircleFill size={16} className="mr-1" />
                    <strong>{translate("global.priceBoxit")}</strong>
                  </div>
                  <div className="ml-4">{item?.Precio}</div>
                </div>
              </div>
            )}
          </div>

          <div className="checkoutButton">
            <div className="d-flex justify-content-between aling-items-center flex-row">
              <div>
                <small>{translate("global.state")}</small>
                <div className="priceTag text-uppercase">
                  {item?.IdEstatusCompra
                    ? getLabelEstatusCompraCliente(
                        item.IdEstatusCompra,
                        item.EstatusPaquete
                      )
                    : ""}
                </div>
              </div>

              {item?.Link && (
                <a href={item.Link} target="_blank" rel="noopener noreferrer">
                  <button className="preorder">
                    <div className="mr-2">
                      <IoMdDownload size={20} />{" "}
                      {translate("global.viewInvoicing")}
                    </div>
                  </button>
                </a>
              )}
            </div>

<div className="mt-1">
{
  item?.EstatusPaquete === 3 && (
    <>
          <div className="d-flex aling-content-center" style={{color:"#f0c800"}}>
           <FaLocationDot size={14} className="mr-2" />
            <span>
              {item?.Plataforma
                ? item.Plataforma
                : ""}
            </span>
            </div>

            <div className="d-flex aling-content-center" style={{color:"#f0c800"}}>
           <FaRegClock size={14} className="mr-2" />
            <span> {translate("global.removeItUntilDay")} {" "}
              {item?.FechaExpiracion
                ? getFormatDate(item.FechaExpiracion)
                : ""}
            </span>
            </div>
    </>
  )
}

{
  item?.EstatusPaquete === 4 && (
    <>
         <div className="d-flex aling-content-center" style={{color:"#00C04B"}}>
           <FaCircleCheck size={14} className="mr-2" />
            <span> {item?.Plataforma
                ? item.Plataforma
                : ""} - {" "}
              {item?.FechaRetiro
                ? getFormatDate(item.FechaRetiro)
                : ""} 
            </span>
            </div>
    </>
  )
}
</div>

            
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemClientsCanvas;
