import { useEffect, useState } from "react";
import { translate } from "../../utils/Common";
import * as FaIcons from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import SearchFilters from "../SearchFilters";
import { FiBox } from "react-icons/fi";
import "./index.css";

const ShowCanvasFilters = ({
  itemArray,
  Item,
  noItemsFound,
  actualPage,
  itemsShowing,
  handleGetData,
  configFilter,
  setConfigFilter,
  parameters,
}) => {
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [actualOption, setActualOption] = useState("");
  const [platformSelect, setPlatformSelect] = useState(null);
  const totalPages = configFilter.totalPages || 1;

  const handleViewSearch = () => {
    setOpenSearch(!openSearch);
  };

  const handleResetAllFilters = () => {
    setSelectedFilters([]);
    handleViewSearch();
    // setPlatformSelect(null);
  };

  const goToPage = (page) => {
    const config = { ...configFilter, currentPage: page };

    setConfigFilter(config);
    setActualOption("GO_TO_PAGE");
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  useEffect(() => {
    handleGetData(configFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {
      ...selectedFilters.reduce((acc, filter) => {
        acc[filter.id] = filter.searchTerm;
        return acc;
      }, {}),
      ...configFilter,
      currentPage: actualOption === "GO_TO_PAGE" ? configFilter.currentPage : 1,
    };

    handleGetData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, configFilter.currentPage, platformSelect]);

  return (
    <div className="relative">
      <div
        style={{
          padding: "25px",
          position: "sticky",
          top: "0",
          backgroundImage: "linear-gradient(120deg, #343A4F, #0F1620)",
          zIndex: 15,
        }}
      >
        <div className="header-filter-box">
          <div className="text-left d-flex" style={{ alignContent: "center" }}>
            <h5 style={{ color: "#F9ED32" }}>
              <FiBox size={24} className="mr-1" />
              {translate("global.myPackages")}
            </h5>

            <span className="ml-3 mt-1" style={{ color: "white" }}>
              {configFilter.totalItems?.toLocaleString("en-US")}{" "}
              {translate("global.total")}
            </span>
          </div>

          <div
            className="d-flex"
            style={{ gap: "20px", position: "relative", color: "white" }}
          >
            <div className="d-flex">
              <button
                className="btn btn-white"
                style={{ color: "white" }}
                onClick={() => handleViewSearch()}
              >
                {selectedFilters.length > 0 && (
                  <span
                    class="badge mr-1"
                    style={{
                      background: "rgb(249, 237, 50)",
                      color: "#0f1620",
                    }}
                  >
                    {selectedFilters.length}
                  </span>
                )}
                {translate("global.filters")}
                <IoFilter className="ml-2" />
              </button>

              {openSearch && (
                <div
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "60px",
                    background: "#fff",
                    padding: "25px",
                    boxShadow: "0px 0px 10px rgba(92, 92, 92, 0.211)",
                    borderRadius: "16px",
                    zIndex: 99,
                    width: "max-content",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-sm"
                    style={{
                      position: "absolute",
                      top: "-5px",
                      right: "0",
                      color: "white",
                      background: "gray",
                      padding: "0 5px",
                      borderRadius: "50px",
                    }}
                    onClick={() => handleViewSearch()}
                  >
                    &times;
                  </button>
                  <SearchFilters
                    parameters={parameters}
                    viewPlatforms
                    handleGetData={handleGetData}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    setActualOption={setActualOption}
                    platformSelect={platformSelect}
                    setPlatformSelect={setPlatformSelect}
                    handleResetFilters={handleResetAllFilters}
                  />
                </div>
              )}

              <div>
                <button
                  className="btn btn-white ml-2"
                  style={{ color: "white" }}
                  onClick={() => goToPage(configFilter.currentPage - 1)}
                  disabled={
                    configFilter.currentPage === 1 || itemArray === null
                  }
                >
                  <FaIcons.FaChevronCircleLeft size={18} />
                </button>

                <button
                  className="btn btn-white ml-2"
                  onClick={() => goToPage(configFilter.currentPage + 1)}
                  style={{ color: "white" }}
                  disabled={
                    configFilter.currentPage === configFilter.totalPages ||
                    itemArray === null
                  }
                >
                  <FaIcons.FaChevronCircleRight size={18} />
                </button>
              </div>

              <div className="d-flex align-items-center ml-2">
                <span>
                  {configFilter.currentPage} {translate("global.of")}{" "}
                  {configFilter.totalPages}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Render options */}

      <div className="grid-container p-4">
        {itemArray ? (
          itemArray?.length > 0 ? (
            <>
              {itemArray.map(
                (item, index) =>
                  index >= actualPage * itemsShowing - itemsShowing &&
                  index < actualPage * itemsShowing && (
                    <Item key={index} index={index} item={item} />
                  )
              )}
            </>
          ) : (
            <div>
              {noItemsFound ? noItemsFound : translate("global.noResults")}
            </div>
          )
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div
              className="spinner-border text-fucsia"
              role="status"
              style={{ color: "black" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>

      {itemArray && (
          itemArray?.length > 0 && (
            <div className="pagination-container pt-4">
                <button
                  className="pagination-btn"
                  onClick={() => goToPage(1)}
                  disabled={configFilter.currentPage === 1}
                >
                  {"<"}
                </button>

                {generatePageNumbers().map((number) => (
                  <button
                    key={number}
                    className={`pagination-btn ${
                      number === configFilter.currentPage ? "active" : ""
                    }`}
                    onClick={() => goToPage(number)}
                  >
                    {number}
                  </button>
                ))}

                <button
                  className="pagination-btn"
                  onClick={() => goToPage(totalPages)}
                  disabled={configFilter.currentPage === totalPages}
                >
                  {">"}
                </button>
              </div>
          ) )}
      

    </div>
  );
};

export default ShowCanvasFilters;
