import { useRef, useState } from "react";
import { CiUnread } from "react-icons/ci";
import { CiRead } from "react-icons/ci";

import "./index.css";
import FiltersLocations from "./FiltersLocations";
// import { scrollToPosition } from "../../../../utils/Common";

const SectionLocations = () => {

  const [showLocations, setShowLocations] = useState(false);

  const toggleLocations = () => {
    setShowLocations(!showLocations);
    !showLocations && handleScroll(); 
  };

  const refSectionSearchLockers = useRef(null);

  const handleScroll = () => {
    refSectionSearchLockers.current.scrollIntoView({ behavior: 'smooth' });
  };

 
  return (
    <>
    <div className="container-stepsLocations" id='section-locations'>
      <div style={{position:"relative",zIndex:1}}>

      {/* Title */}
      <div className="title-stepsLocations">
        <div >Nuestras ubicaciones</div>
      </div>

      <div className="row content-section ">
        <div className="col-lg-6 col-sm-12 d-flex justify-content-center flex-column text-section">
          <p className="text-start">
          Llegó Lo que pedí utiliza la avanzada tecnología de la red de <strong>casilleros inteligentes</strong> de Boxit, que cuenta con ubicaciones convenientes en toda la ciudad. Gracias a Boxit, podrás <strong className="highlight">retirar tu paquete</strong> de una manera más <strong className="highlight">rápida</strong>, <strong className="highlight">segura</strong> y <strong className="highlight">económica</strong>.
          </p>

          <div className="d-flex justify-content-center mt-5">
            <button className="btn btn-black-llego" id="btn-locations-llego" onClick={toggleLocations}>Ver sucursales</button> 
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 d-flex align-items-end justify-content-end">
          {<img src="../img/locker-boxit2.png" alt="Locker Boxit" className="img-fluid locker-image" /> }
        </div>
      </div>
      

      </div>

      {/* Elements Background */}
      <img src="../img/llego_pluma.png" className="hojas_locations" alt="llego_pluma.png" id="hoja_box_locations" />
      
            <img src="../img/llego_pluma.png" className="hojas_locations" alt="llego_pluma.png" id="hoja2_box_locations" />
            <img src="../img/llego_pluma.png" className="hojas_locations" alt="llego_pluma.png" id="hoja3_box_locations" />
            <img src="../img/llego_pluma.png" className="hojas_locations" alt="llego_pluma.png" id="hoja4_box_locations" />
            <img src="../img/boxLlego.png" className="hojas_locations" alt="boxLlego.png" id="box1_locations" />
            <img src="../img/boxLlego.png" className="hojas_locations" alt="boxLlego.png" id="box2_locations" />
       
      <img src="../img/img_llego_nube1.png" className="nubes_stepsbuy" alt="img_llego_nube2.png" id="nube1_stepsplans" />
      <img src="../img/img_llego_nube3.png" className="nubes_stepsbuy" alt="img_llego_nube3.png" id="nube3_stepsplans" />
      {!showLocations && <img src="../img/img_llego_nube3.png" className="nubes_stepsbuy" alt="img_llego_nube3.png" id="nube2_stepsplans" />}
    
      
    </div>
    <div ref={refSectionSearchLockers}>     
    {showLocations && (<FiltersLocations setOpen={toggleLocations}  background={"#85CDE4"}/>)}
    </div>
    </>
  );
};

export default SectionLocations;
