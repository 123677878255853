import { useEffect, useState } from "react";
import "./index.css";
import { FaCloudUploadAlt, FaFilePdf, FaFileImage, FaTrashAlt } from "react-icons/fa";
import { translate } from "../../utils/Common";

const UploaderFile = ({ name, register, isRequired, errors, resetFile, accept, setError }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(translate("global.NoSelectedFile"));
  const [fileSize, setFileSize] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        setError(
          name,
          {
            type: "manual",
            message: translate("global.filesAlert5MB"),
          }
        );

        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileSize((selectedFile.size / 1024).toFixed(2) + " KB");
      setFileType(selectedFile.type);
    }
  }

  useEffect(() => {
    if (resetFile) {
      setFile(null);
      setFileName(translate("global.NoSelectedFile"));
      setFileSize(null);
      setFileType(null);
    }
  }, [resetFile]);

  const getFileIcon = () => {
    switch (fileType) {
      case "application/pdf":
        return <FaFilePdf color="#e74c3c" size={35} className="mr-2" />;
      case "image/png":
      case "image/jpeg":
        return <FaFileImage color="#3498db" size={35} className="mr-2" />;
      default:
        return <FaCloudUploadAlt color="#f9ed32" size={40} />;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {errors.fileSelect && <span className="text-danger">{errors.fileSelect.message}</span>}
      <input
        type="file"
        id="input-field-file"
        accept={accept}
        style={{ opacity: 0, position: "absolute", zIndex: 0, top: "50%", fontSize: "1px" }}
        onChange={handleFileChange}
        ref={register({ required: isRequired && translate("login.signup.required") })}
        name={name}
        required={isRequired}
      />
      <div
        className="main-uploader mt-4"
        style={{ zIndex: 1 }}
        onClick={() => document.querySelector("#input-field-file").click()}
      >
        {file ? (
          <div className="d-flex align-items-center">
            {getFileIcon()}
            <div className="d-flex align-items-start justify-content-center flex-column">
              <span className="mr-2 text-bold">{fileName}</span>
              <p className="mr-2">({fileSize})</p>
            </div>
            <button
              style={{ position: "absolute", top: "-10px", right: "-5px", borderRadius: "50px" }}
              className="btn btn-danger ml-2"
              onClick={() => {
                setFile(null);
                setFileName("No selected file");
                setFileSize(null);
                setFileType(null);
              }}
            >
              <FaTrashAlt />
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <FaCloudUploadAlt color="#f9ed32" size={40} />
            <span className="ml-2">{translate("global.uploadFile")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploaderFile;
