import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SidebarProvider from '../contexts/sidebar/Provider';

// components
import SignIn from '../views/Login/SignIn';
import SignUp from '../views/Login/SignUp';
import ForgotPassword from '../views/Login/ForgotPassword';
import ResetPassword from '../views/Login/ResetPassword';
import Dashboard from '../views/Dashboard';
import NotFound from '../views/NotFound';
import Activate from '../views/Login/Activate';
import Home from '../views/Landing/Home';
import Plans from '../views/Landing/Plans';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';


const Routes = () => {
    return (
        <SidebarProvider>
            <Router>
                <Switch>
                    <PrivateRoute path='/dashboard' component={Dashboard} />
                    <PublicRoute path='/' component={Home} exact />
                    <PublicRoute path='/planes' component={Plans} exact />
                    <Redirect exact from='/' to='/' />
                    <PublicRoute path='/login' component={SignIn} exact />
                    <PublicRoute path='/registrar' component={SignUp} exact />
                    <PublicRoute path='/forgotpwd' component={ForgotPassword} exact />
                    <PublicRoute path='/resetpwd/:id' component={ResetPassword} exact />
                    <Route path='/activate/:country/:token' component={Activate} exact /> 
                    <Route path='*' component={NotFound} exact /> 
                </Switch>
            </Router> 
        </SidebarProvider>    
    );
}

export default Routes;