import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translate } from '../../../utils/Common';
const ForgotPwdSuccess = () => {
    return (
        <div className="container text-center">
            <Alert variant="info">
                <h5 className="h5 font-weight-normal">{translate("login.forgotpwd.success.title")}</h5>
                <p className="mb-2" >{translate("login.forgotpwd.success.description1")}</p>
                <p className="mb-2 text-bold">#{translate("login.forgotpwd.success.description3")}!</p>
            </Alert>
            <Link to="/login" type="button" className="btn btn-primary-llego rounded-pill btn-lg text-uppercase mb-3">{translate("login.forgotpwd.success.accept")}</Link>
        </div>
    );
}

export default ForgotPwdSuccess;