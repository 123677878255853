import * as FaIcons from 'react-icons/fa';
import { HiBellAlert } from "react-icons/hi2";
import { GoPackage } from "react-icons/go";
import {translate} from '../../../utils/Common';

const Options = (user) => {

    const userOptions = [
        {
            title: translate('sidebar.options.start'),
            path: '/dashboard',
            icon: <FaIcons.FaHome />
        },
        {
            title: translate("global.editProfile"),
            path: '/dashboard/profile',
            icon: <FaIcons.FaUser/>
        },
        {
            title: translate("global.alertPackage"),
            path: '/dashboard/package/alert',
            icon: <HiBellAlert size={20}/>
        },
        {
            title: translate("global.myPackages"),
            path: '/dashboard/package/list',
            icon: <GoPackage size={20}/>
        },
        {
            title: translate('sidebar.options.faqs'),
            path: '/dashboard/faqs',
            icon: <FaIcons.FaQuestion/>
        }
    ].filter(option => option);
  
    return userOptions;
} ;

    export default Options; 