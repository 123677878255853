import { FaUser } from "react-icons/fa";
import { GiPlatform } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { setGenderUser, translate } from "../../../../utils/Common";
import { useUsers } from "../../../../hooks/useUsers";
import Spinner from "../../../../components/Spinner"; 
import DatePickerBirth from "../../../../components/DatePickerBirth";

const ClientInfoPanel = () => {
  
  const { getUserInformation } = useUsers();
  const [dataClient, setDataClient] = useState(null); 

  useEffect(() => {
    const abortController = new AbortController();
    const handleResponse = async () => {
      const response = await getUserInformation(abortController.signal);
      setDataClient(response);      
    };

    handleResponse();
    return () => abortController.abort();

  }, []);

  return (
    <>
      <div className="dashboardCard m-0 w-100 text-left">
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex justify-content-between mb-3">
            <div
              style={{ fontSize: "20px" }}
              className="d-flex align-items-center"
            >
              <FaUser className="mr-2" />
              <div className="text-bold">{translate("global.personalInformation")}</div>
            </div>
            {/* <div>
              <Link
                to="/dashboard/profile/"
                className="btn btn-primary-llego rounded-pill"
                onClick={() => sidebarContext.handleChangeCurrentPage(translate("global.editProfile"))}
              >
                <FaEdit size={16} /> Editar
              </Link>
            </div> */}
          </div>

          <div className="dropdown-divider"></div>

          {dataClient ? (
              <>
                 <div
            className="row text-left"
            style={{ fontSize: "14px", padding: "0 18px" }}
          >
            <div className="col-6 p-2">
              <div className="text-bold">{translate("global.name")}</div>
              <div className="form-control form-control-sm ">{dataClient?.Nombre ?? ""}</div>

            </div>
            <div className="col-6 p-2">
              <div className="text-bold">{translate("login.signup.lastname")}</div>
              <div className="form-control form-control-sm">{dataClient?.Apellidos ?? ""}</div>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 p-2">
              <div className="text-bold">{translate("login.signup.email")}</div>
              <div className="form-control form-control-sm" >
              {dataClient?.Email ?? ""}
              </div>
            </div>
            <div className="col-lg-6 col-12 p-2">
              <div className="text-bold">{translate("global.phone")}</div>
              <div className="form-control form-control-sm">{dataClient?.Telefono[0] ?? ""}</div>
            </div>

            <div className="col-12 p-2">
                <label className="text-bold">{translate("dashboard.userprofile.personal.birthdate_label")}</label>
                <DatePickerBirth defaultDate={dataClient.FechaNacimiento ?? ""} disabled={true} />
              </div>

            <div className="col-lg-6 col-12 p-2">
              <div className="text-bold">{translate("global.gender")}</div>
              <div className="form-control form-control-sm">{setGenderUser(dataClient?.Sexo ?? "")}</div>
            </div>          
          </div>

          

          

          <div className="p-3">
            <div className="text-bold mb-4" style={{ fontSize: "16px" }}>
              {translate("global.wherePickUpPackages")}
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  background: "black",
                  color: "white",
                  borderRadius: "13px",
                  gap: "20px",
                }}
                className="p-4 m-2 d-flex"
              >
                <div>
                  <GiPlatform size={30} color="#F9ED32" />
                </div>
                <div>
                <div
                    className="text-bold d-flex align-items-center"
                    style={{ fontSize: "12px", gap: "10px", color:"#F9ED32" }}
                  >
                    {translate("global.lockerPreference")}:
                  </div>
                  <div
                    className="text-bold d-flex align-items-center"
                    style={{ fontSize: "18px", gap: "10px" }}
                  >
                    {dataClient?.BoxitNombre ?? ""}
                  </div>
                  <p className="mb-2" style={{fontSize:"12px"}}>{dataClient?.BoxitDireccion ?? ""}</p>
                  
                  {dataClient?.BoxitUbicacion && (
                    <a href={dataClient?.BoxitUbicacion ?? ""} target="_blank"  rel="noreferrer" style={{ color: "#F9ED32" }}>
                    <FaLocationDot /> <u>{translate("global.viewMap")}</u>
                  </a>
                  )}

                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "-25px",
                  right: "-10px",
                  background: "#2DAC31",
                  borderRadius: "13px",
                  padding: "5px 25px",
                  color:"white"
                }}
              >
                <div className="text-bold">{translate("global.balance")}</div>
                <div>$ {(dataClient?.SaldoCliente ?? 0).toFixed(2)}</div>
              </div>
            </div>
          </div>
              </>
            ) : (
              <div className="d-flex justify-content-center p-2">
              <Spinner type={1} size={18} />
            </div>
            )}
         

        </div>
      </div>
    </>
  );
};

export default ClientInfoPanel;
