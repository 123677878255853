import { useState, useContext } from "react";
import AuthContext from "../../../contexts/auth";
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
// icons
import * as MdIcons from 'react-icons/md';
// components
import Spinner from "../../../components/Spinner";
import {translate} from "../../../utils/Common";

const FormForgotPwd = ({ setForgotPwdSuccess }) => {
    const userContext = useContext(AuthContext);
    const { register, handleSubmit, reset, setError, errors } = useForm();

    // to handle server response
    const [isLoading, setIsLoading] = useState(null);

    const onSubmit = async (data) => {
        try {
            if (data.email.includes('@') && data.email.includes('.')) {
                setIsLoading(true);
                const result = await userContext.forgotPwd(data);
                setIsLoading(false);

                switch (result) {
                    case 'EMAIL_SENT_SUCCESSFULLY':
                        reset();
                        setForgotPwdSuccess(true);
                        break;
                    case 'EMAIL_IS_NEEDED':
                        setError('email', { type: '', message: translate("login.forgotpwd.validate.emailNeeded") });
                        break;
                    case 'INVALID_EMAIL':
                        setError('email', { type: '', message: translate("login.forgotpwd.validate.emailInvalid") });
                        break;
                    case 'EMAIL_NOT_FOUND':
                        setError('email', { type: '', message: translate("login.forgotpwd.validate.emailNotFound") });
                        break;
                    case 'CLIENT_IS_INACTIVE':
                        setError('email', { type: '', message: translate("login.forgotpwd.validate.clientInactive") });
                        break;
                    case 'ERROR':
                        setError('email', { type: '', message: translate("login.forgotpwd.validate.errorServer") });
                        break;
                    
                    default:
                        break;
                }
            }
            else {
                setError('email', { type: '', message: translate("login.forgotpwd.validate.emailError") });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
            <h5 className="mb-2">{translate("login.forgotpwd.title")}</h5>
            <p className="mb-4">{translate("login.forgotpwd.instructions")}</p>

            <div className="form-group text-left px-3 mb-4">
                <label htmlFor="inputEmail" className="mb-1"><span className="text-danger">*</span> {translate("login.forgotpwd.email")}</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputEmail">
                            <MdIcons.MdEmail size={18} className="text-dark"/>
                        </span>
                    </div>
                    <input type="email" className={ "form-control form-control-md" + (errors.email ? " is-invalid" : "") } id="inputEmail" name="email" required ref={register({ required: true })}/>
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                </div>
            </div>

            <div className="form-group mt-2 mb-0">
                <button className="btn btn-primary-llego btn-forgot rounded-pill btn-lg text-uppercase m-2" type="submit">
                    { !isLoading ? translate("login.forgotpwd.buttonSend") : <Spinner type={1} size={18} /> }
                </button>
                <Link to='/login'><button className="btn btn-secondary-llego btn-forgot rounded-pill btn-lg text-uppercase m-2">{translate("login.forgotpwd.buttonReturn")}</button></Link>
            </div>
        </form> 
    );
}

export default FormForgotPwd;