import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { es, pt, enUS } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { getFormatDate, sqlDateFormat, translate } from "../../utils/Common";

const DatePickerBirth = (props) => {
  const langStorage = localStorage.getItem("language");
  const lang = langStorage ? langStorage : "enUS";

  const locales = {
    es: es,
    pt: pt,
    en: enUS,
  };

  const selectedLocale = locales[lang] || enUS;
  registerLocale(lang, selectedLocale);

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    if(date){
  const adjustedDate = new Date(date);
  adjustedDate.setHours(0);
  adjustedDate.setMinutes(0);
  adjustedDate.setSeconds(0);
  
  setSelectedDate(date);
  props.setFormDateBirth(adjustedDate.toISOString().split("T")[0]);
    }else{
      setSelectedDate("");
      props.setFormDateBirth("");
    }
  };

  const placeHolderText = translate("global.day")+"-"+translate("global.month")+"-"+translate("global.year");
  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    if (props.defaultDate) {
      const dateObject = new Date(props.defaultDate);
      dateObject.setDate(dateObject.getDate() + 1);

      setSelectedDate(dateObject);
    }
  }, [props.defaultDate]);

  return (
    <div className="form-group mb-0 d-flex align-items-center" style={{gap:"5px", width:"100%"}}>
      <div style={{width:selectedDate ? "170px" : "100%"}}>
      {props.disabled ? <input type="text" className="form-control form-control-sm" style={{width:"170px"}} placeholder={props.defaultDate ? getFormatDate(props.defaultDate) : placeHolderText  } disabled/> 
      : 
      <DatePicker
      className={"form-control "+ props.className}
      dateFormat="dd-MM-yyyy"
      placeholderText={placeHolderText}
      selected={selectedDate}
      onChange={handleDateChange}
      locale={lang}
      disabled={props.disabled}
      ref={props.register}
      name="birthDate"
    />}
      </div>
   
    {selectedDate && (
      <p className="mb-0" ><span style={{background:"black", color:"#F9ED32", padding:"8px",  borderRadius:"0 25px 25px 0", borderLeft:"4px solid #F9ED32"}}>{translate("global.age")}: <strong>{calculateAge(selectedDate)} {translate("global.years")}</strong></span></p> // Agregamos una clase de margen superior para separar el texto
    )}
  </div>
  );
};

export default DatePickerBirth;
