import React, { useEffect } from 'react';
import { setClientDomain, translate } from './utils/Common';
import AuthProvider from "./contexts/auth/Provider";
import './style/App.css';
// rutas
import Routes from './routes/routes';

const App = () => {
  useEffect(() => { setClientDomain(window.location.hostname); }, []);
  const hostname = window.location.hostname;

  return (
    <AuthProvider>
      {hostname !== 'loquepedi.com' && hostname !== 'www.loquepedi.com' &&
        <div className={`d-flex align-items-center justify-content-center dev-env`}> 
          {translate("global.devEnv")}
        </div>
      }
      <Routes />
    </AuthProvider>
  );
}

export default App;
