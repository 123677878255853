import "./index.css";

const StepsBuyOnline = () => {
  return (
    <div className="container-stepsbuy" id="section-stepsbuy">
      {/* Title */}
      <div style={{position:"relative",zIndex:1}}>
      <div className="title-stepsbuy" >
        <div className="mt-4">TRAE TUS PAQUETES CON</div>
        <img
          src="../img/logoTitle_llego_black.png"
          alt="logoTitle_llego_black"
          className="logoTitle_llego_stepsbuy"
        />
      </div>

      {/* Steps */}
      <div className="px-5 row">
        {/* Step 1 */}
        <div className="col-lg-4 col-md-6">
          <div className="item-stepsbuy" style={{ transform: "rotate(3deg)" }}>
            <div
              className="number-stepsbuy"
              style={{ position: "absolute", top: "-25px", left: "15px" }}
            >
              <img
                src="../img/1-number-stepsbuy.png"
                className="number-img-stepsbuy"
                alt="1-number-stepsbuy.png"
                
              />
            </div>
            <div className="text-stepsbuy">REALIZA EL REGISTRO Y ACTIVA TU CUENTA</div>
            <div
              className="icon-stepsbuy"
              style={{ position: "absolute", bottom: "-60px", right: "-75px" }}
            >
              <img
                src="../img/pc-icon-stepsbuy.png"
                className="icon-img-stepsbuy"
                alt="pc-icon-stepsbuy.png"
                
              />
            </div>
          </div>
        </div>

{/* Step 2 */}
        <div className="col-lg-4 col-md-6" id="number2-stepsbuy">
          <div className="item-stepsbuy" style={{ transform: "rotate(-3deg)" }}>
            <div
              className="number-stepsbuy"
              style={{ position: "absolute", top: "-30px", left: "15px" }}
            >
              <img
                src="../img/2-number-stepsbuy.png"
                className="number-img-stepsbuy"
                alt="2-number-stepsbuy.png"
                
              />
            </div>
            <div className="text-stepsbuy">INGRESA A TU PERFIL Y OBTÉN TU DIRECCIÓN EN MIAMI</div>
            <div
              className="icon-stepsbuy"
              style={{ position: "absolute", bottom: "-65px", right: "-50px" }}
            >
              <img
                src="../img/box-icon-stepsbuy.png"
                style={{height:"150px"}}
                alt="pc-icon-stepsbuy.png"
                
              />
            </div>
          </div>
        </div>

{/* Step 3 */}
        <div className="col-lg-4 col-md-6" id="number3-stepsbuy">
          <div className="item-stepsbuy"  >
            <div
              className="number-stepsbuy"
              style={{ position: "absolute", top: "-30px", left: "40%" }}
             
            >
              <img
                src="../img/3-number-stepsbuy.png"
                className="number-img-stepsbuy"
                alt="3-number-stepsbuy.png"
                
              />
            </div>
            <div className="text-stepsbuy">REALIZA TU COMPRA Y ESPERA EL PEDIDO</div>
            <div
              className="icon-stepsbuy"
              style={{ position: "absolute", bottom: "-90px", right: "-50px" }}
            >
              <img
                src="../img/buycart-icon-stepsbuy.png"
                className="icon-img-stepsbuy"
                alt="buycart-icon-stepsbuy.png"
                
              />
            </div>
            
          </div>
        </div>

      </div>

      <div className="px-5 row mt-5" style={{display: "flex", justifyContent:"center"}}>

{/* Step 4 */}
      <div className="col-lg-4 col-md-6" id="number4-stepsbuy">
          <div className="item-stepsbuy" style={{ transform: "rotate(-3deg)" }}>
            <div
              className="number-stepsbuy"
              style={{ position: "absolute", top: "-30px", right: "15px" }}
            >
              <img
                src="../img/4-number-stepsbuy.png"
                className="number-img-stepsbuy"
                alt="4-number-stepsbuy.png"
                
              />
            </div>
            <div className="text-stepsbuy">TU PAQUETE VIAJA A PANAMÁ</div>

            <div
              className="icon-stepsbuy"
              style={{ position: "absolute", bottom: "-45px", left: "-5px" }}
            >
              <img
                src="../img/boxLlego.png"
                style={{height:"60px"}}
                alt="boxLlego-icon-stepsbuy.png"
                
              />
            </div>

            <div
              className="icon-stepsbuy"
              style={{ position: "absolute", bottom: "-120px", left: "195px" }}
            >
              <img
                src="../img/LLEGO_casita_pajaro.png"
                style={{height:"95px"}}
                alt="LLEGO_casita_pajaro-icon-stepsbuy.png"
                
              />
            </div>

            <div
              className="icon-stepsbuy"
              style={{ position: "absolute", bottom: "-80px", left: "40px", zIndex:"-1"}}
            >
              <img
                src="../img/llego-lines.png"
                style={{height:"50px"}}
                alt="llego-lines-icon-stepsbuy.png"
                
              />
            </div>

            <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hojas4" />

          </div>
        </div>

{/* Step 5 */}
        <div className="col-lg-4 col-md-6">
          <div className="item-stepsbuy" >
            <div
              className="number-stepsbuy"
              style={{ position: "absolute", top: "-30px", left: "15px" }}
            >
              <img
                src="../img/5-number-stepsbuy.png"
                className="number-img-stepsbuy"
                alt="5-number-stepsbuy.png"
                
              />
            </div>
            <div className="text-stepsbuy">RETÍRALO EN TU BOXIT FAVORITO</div>
            <div
              className="icon-stepsbuy"
              style={{ position: "absolute", bottom: "-130px", right: "-60px"}}
            >
              <img
                src="../img/locker-boxit2.png"
                className="icon-img-stepsbuy"
                alt="box-icon-stepsbuy.png"
                style={{height:"160px"}}
                
              />
            </div> 
          </div>
        </div>

        {/* <div className="col-lg-3 col-md-6 "style={{marginTop:"6em"}}>
          <div className="item-stepsbuy" style={{ transform: "rotate(-3deg)" }}>
            <div
              className="number-stepsbuy"
              style={{ position: "absolute", top: "-20px", left: "15px" }}
            >
              <img
                src="../img/6-number-stepsbuy.png"
                className="number-img-stepsbuy"
                alt="2-number-stepsbuy.png"
                
              />
            </div>
            <div className="text-stepsbuy">VOY PA’ LLÁ</div>
            <div
              className="number-stepsbuy"
              style={{ position: "absolute", top: "-80px", right: "-50px" }}
            >
              <img
                src="../img/Llego_PajaritoAmarillo.png"
                style={{height:"90px"}}
                alt="Llego_PajaritoAmarillo.png"
                
              />
            </div>

            <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hojas3" />

          </div>
        </div> */}

     
      </div>
      </div>
     

      {/* Elements Background */}
      <img src="../img/img_llego_nube2.png" className="nubes_stepsbuy" alt="img_llego_nube2.png" id="nube1" />
      <img src="../img/img_llego_nube3.png" className="nubes_stepsbuy" alt="img_llego_nube3.png" id="nube2" />
      <img src="../img/img_llego_nube3.png" className="nubes_stepsbuy" alt="img_llego_nube3.png" id="nube3" />
      <img src="../img/img_llego_nube1.png" className="nubes_stepsbuy" alt="img_llego_nube1.png" id="nube4" />
      {/* <img src="../img/Llego_Casita.png" alt="casitaLlego.png" id="casitaLlego" />
      <img src="../img/llego-lines.png" alt="llego-lines.png" id="linesLlego" /> */}
      {/* -- Hojas */}
      <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hojas1" />
      {/* <img src="../img/llego_pluma.png" className="hojas_stepsbuy" alt="llego_pluma.png" id="hojas2" /> */}

    </div>
  );
};

export default StepsBuyOnline;
