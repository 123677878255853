import { Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { translate } from '../../../utils/Common';

const SignUpSuccess = () => {
    return  <div>
                <Alert variant='success' className="font-weight-normal mb-4 mt-3">
                    <div>
                        <h6>{translate("login.signup.success.title")}</h6>
                        <div className="text-justify">
                            {/* <p className="mb-2">{translate("login.signup.success.description1")} <span className="font-bold">#{translate("login.signup.success.description2")}.</span></p> */}
                            <p className="mb-2">{translate("login.signup.success.description3")}.</p>
                            <p className="mb-2 text-center">{translate("login.signup.success.description4")}.</p>
                        </div>
                    </div>
                </Alert>
                <Link to="/login"><button className="btn btn-primary-llego btn-signup rounded-pill text-uppercase m-2">{translate("login.signup.success.return")}</button></Link>
            </div>
}

export default SignUpSuccess;