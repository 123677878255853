import "./index.css";
import { FaSearch } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { useEffect, useState } from "react";
import { translate } from "../../../../../utils/Common";
import { usePlatforms } from "../../../../../hooks/usePlatforms";
import Spinner from "../../../../../components/Spinner";

const FiltersLocations = ({ setOpen, background }) => {
  const { getAllPlatformsPublic } = usePlatforms();
  const [searchText, setSearchText] = useState('');
  
  const [plataformasList, setPlataformasList] = useState([]);
  const [filteredItems, setFilteredItems] = useState(plataformasList);
  const [currentPage, setCurrentPage] = useState(1);
  
  const itemsPerPage = 3;

  useEffect(() => {
    const lowercasedFilter = searchText.toLowerCase();
    const filteredData = plataformasList.filter(item =>
      item.locationName.toLowerCase().includes(lowercasedFilter) ||
      item.locationAddress[0].toLowerCase().includes(lowercasedFilter)
    );
    setFilteredItems(filteredData);
    setCurrentPage(1);
  }, [searchText, plataformasList]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const goToPreviousPage = () => {
    setCurrentPage(prevState => (prevState === 1 ? prevState : prevState - 1));
  };

  const goToNextPage = () => {
    setCurrentPage(prevState => (prevState === totalPages ? prevState : prevState + 1));
  };

  useEffect(() => {
    const abortController = new AbortController();

    const handlePlatforms = async () => {
      const platforms = await getAllPlatformsPublic(abortController.signal);
      setPlataformasList(platforms);
    };

   handlePlatforms();
    
    return () => abortController.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(plataformasList)


  return (
    
        <div className="locations-container" style={{background:background}}>
        <hr style={{ border: "1px white dashed", margin: "30px 0" }} />
  
       { plataformasList.length > 0 ? (
        <>    
        <div className="cont-locations-filters">
          <div className="row">
            <div className="col-lg-5 mt-4">
              <div className="search-filter-locations">
                <FaSearch size={18} />
                <input
                  type="text"
                  className="input-filter-locations"
                  placeholder="Buscar"
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
  
            <div className="col-lg-4 col-8 mt-4">
              <div className="pagination-filter-locations">
                <div className="btn-pagination-filter-locations" onClick={goToPreviousPage}>
                  <FaArrowLeft />
                </div>
                <div className="text-pagination-filter">{currentPage} de {totalPages}</div>
                <div className="btn-pagination-filter-locations" onClick={goToNextPage}>
                  <FaArrowRight />
                </div>
              </div>
            </div>
  
            <div className="col-lg-3 col-4 mt-4">
              <a href="#section-locations" className="btn-close-filter-locations" onClick={setOpen}>
                <IoIosCloseCircle size={18} /> Cerrar
              </a>
            </div>
          </div>
        </div>
  
        <div className="mt-5 cont-locations row">
          {currentItems.map((item) => (
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4" key={item.boxitLocationId}>
              <div className="location-item">
                <div>
                  <img
                    src={item.locationPhoto}
                    alt="Location"
                    className="location-item-img"
                  />
                </div>
                <div className="location-item-header">
                  <div>
                    <div className="location-item-title mb-3">{item.locationName}</div>
                    <div className="location-item-distance">
                      <FaLocationDot /> {item.locationAddress[0]}
                    </div>
                    <div className="location-item-distance">
                      <FaClock /> {item.schedule}
                    </div>
                  </div>
                  <a
                  href={item.BoxitUbicacion}
                  target="_blank"  rel="noreferrer"
                    className="btn btn-black-llego"
                    style={{ fontSize: "14px", marginTop: "15px", width: "100%" }}
                  >
                    {translate("global.viewMap")}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        </>
      ):(
        <div style={{display:"flex",justifyContent: "center", alignContent: "center", padding: "2rem 0 10rem", color:"white"}}>  
          <Spinner type={1} size={30} />
        </div>
      )}
  
        <img src="../img/img_llego_nube3.png" className="nubes_stepsbuy" alt="img_llego_nube3.png" id="nube2_stepsplans" />
      </div>
     
  );
};

export default FiltersLocations;
