import { BiLogoInstagram } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

import "./index.css";

const SectionPrincipal = () => {
  return (
    <div id="section-home">

      <div className="section-home-titles">
        <div style={{position:"relative",   display: "flex",  flexDirection: "column", justifyContent: "center", alignItems:"center"}} >
          <img
            src="../img/logoTitle_llego_white.png"
            alt="logoTitle_llego_white.png"
            className="section-home-logo"
          />
          {/* <h1 className="section-home-logo-title">Lo que pedí</h1> */}

          <a href="/registrar" className="btn btn-yellow-llego rounded-pill" style={{fontSize:"20px", margin:"20px 0", padding:"15px 30px", width:"fit-content", zIndex:"10"}}>Regístrate</a>

          <div className="socialmedia-section-home">
            <a href="https://www.instagram.com/loquepedi" target="_blank" rel="noopener noreferrer" style={{color:"white"}}><BiLogoInstagram size={60}/></a>
              <a href="https://wa.me/50763494559?text=Hola%20Llegó%20,%20¿Podrías%20proporcionarme%20más%20información?" target="_blank" rel="noopener noreferrer" style={{color:"white"}}><FaWhatsapp size={50}/></a>
            {/* <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" style={{color:"white"}}><FaFacebookF size={50}/></a> */}
            {/* <a href="https://www.linkedin.com/loquepedii" target="_blank" rel="noopener noreferrer"  style={{color:"white"}}><AiFillLinkedin size={50} /></a> */}
          </div>

           {/* ELEMENTS EXTRAS */}
        <img src="../img/img_llego_nube3.png" alt="NUBE_3" id="nube1--section-home"/>
        <img src="../img/img_llego_nube3.png" alt="NUBE_3" id="nube2--section-home"/>
        </div>

       
      </div>

      <div className="section-home-img-bird">
        <img
          src="../img/Llego_PajaritoAmarillo.png"
          alt="Llego_PajaritoAmarillo.png"
          className="img-bird-home-page"
        />

        {/* ELEMENTS EXTRAS */}
        {/* NUBES */}
        <img src="../img/img_llego_nube3.png" alt="NUBE_3" id="nube3--section-home" />
        <img src="../img/img_llego_nube2.png" alt="NUBE_2" id="nube4--section-home"/>
        <img src="../img/img_llego_nube2.png" alt="NUBE_2" id="nube5--section-home"/>
        {/* HOJAS */}
        <img src="../img/llego_pluma.png" alt="llego_pluma" id="llego_pluma1--section-home" className="floating-element-slow"/>
        <img src="../img/llego_pluma.png" alt="llego_pluma" id="llego_pluma2--section-home" className="floating-element"/>
        <img src="../img/llego_pluma.png" alt="llego_pluma" id="llego_pluma3--section-home" className="floating-element-slow"/>
        {/* CAJAS */}
        <img src="../img/boxLlego.png" alt="boxLlego" id="caja1--section-home" className="floating-element-slow"/>
        <img src="../img/boxLlego.png" alt="boxLlego" id="caja2--section-home" className="floating-element-slow"/>
        <img src="../img/boxLlego.png" alt="boxLlego" id="caja3--section-home" className="floating-element-slow"/>

      </div>

      <div className="area" >
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    </div >
    </div>
  );
};

export default SectionPrincipal;
