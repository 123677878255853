import "./index.css";

const SectionAboutUs = () => {
  return (
    <div id="section-aboutUs">
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          padding: "4em 0",
          backgroundImage: "url(/img/llego_aboutus.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div style={{ marginBottom: "55px" , position:"relative"}}>
          
          <img
            src="../img/logo_llego_alternative.png"
            alt="logo_llego_alternative.png"
            width={"250px"}
          />
          <img src="../img/img_llego_nube2.png" className="nubes_aboutUs" alt="img_llego_nube2.png" id="nube1_aboutUs"/>

        </div>
      </div>
      <div style={{ position: "relative", background: "#706EDB" }}>
      
          <div className="container-content-aboutUs"
          >
            <div className="text-center">
              Llegó Lo que pedí, con orgullo es una empresa liderada por mujeres, donde el
              empoderamiento y la inclusión son valores fundamentales que 
              reconocen la necesidad de un cambio radical en la industria de la{" "}
              paquetería. Trabajamos para crear un entorno laboral
              equitativo donde todas las voces sean valoradas y
              respetadas.
            </div>
            <br />
            <div className="text-center">
              En Llegó Lo que pedí, no solo entregamos paquetes, estamos transformando la
              manera en que el mundo concibe la logística, liderando con visión
              y tecnología de vanguardia.
            </div>

            <img src="../img/llego_pluma.png" className="hojas_aboutUs" alt="llego_pluma.png" id="hoja1_about_us" />
            <img src="../img/llego_pluma.png" className="hojas_aboutUs" alt="llego_pluma.png" id="hoja2_about_us" />

        </div>

        {/* ELEMENTOS EXTRAS */}
        <img src="../img/img_llego_nube2.png" className="nubes_aboutUs" alt="img_llego_nube2.png" id="nube2_aboutUs"/>
        <img src="../img/img_llego_nube2.png" className="nubes_aboutUs" alt="img_llego_nube2.png" id="nube3_aboutUs"/>
        
      </div>
    </div>
  );
};

export default SectionAboutUs;
