import { useCallback } from "react";
import { getClientDomain, getToken, updateContextUser } from "../utils/Common";

export const useUsers = () => {

  const getUserInformation = useCallback(async (signal) => {
    const response = await fetch(`${getClientDomain()}/users/service/infoPersonal`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${getToken()}`
      },
      signal: signal,
    });
    const result = await response.json();
    if (result?.payload) { 
      updateContextUser(result.payload)
      return result.payload; 
    }
    else return null;
  }, []);

  const getUserAddressMiami = useCallback(async (signal) => {
    const response = await fetch(`${getClientDomain()}/users/service/addressMiami`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${getToken()}`
      },
      signal: signal,
    });
    const result = await response.json();
    if (result?.payload) return result.payload;
    else return null;
  }, []);

  const updateUserInformation = useCallback(async (data ,signal) => {
    const response = await fetch(`${getClientDomain()}/users/update/info`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(data),
      signal: signal,
    });
    const result = await response.json();
    if (result?.payload) return result.payload;
    else return null;
  }, []);

  const updatePasswordUser = useCallback(async (data ,signal) => {
    const response = await fetch(`${getClientDomain()}/users/update/pwd`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${getToken()}`,
        'Credentials': Buffer.from(data.newPwd.toString() + ':' + data.oldPwd.toString()).toString('base64')
      },
      body: JSON.stringify(data),
      signal: signal,
    });
    const result = await response.json();
    
    if (result.code === 200) {
      if (result.payload) return result.payload.Mensaje;
      else return 'USER_NOT_FOUND';
  }
  else if (result.code === 401) return 'TOKEN_EXPIRED';
  else return 'SERVER_ERROR';
  }, []);

  return {
    getUserInformation,
    getUserAddressMiami,
    updateUserInformation,
    updatePasswordUser
  };
};
