import React, { useState } from "react";
import { FaCaretRight } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { translate } from "../../utils/Common"

import "./index.css";

const SearchFilters = ({ parameters, handleGetData, setSelectedFilters, selectedFilters, setActualOption, setPlatformSelect, handleResetFilters }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showResults, setShowResults] = useState(false);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setShowResults(value !== "");
  };

  const handleSelectFilter = (filterId) => {
    const selectedFilter = parameters.find((filter) => filter.id === filterId);
    const filterLabel = selectedFilter.label;

    // Check if filterId already exists in selectedFilters
    const filterIndex = selectedFilters.findIndex((filter) => filter.id === filterId);

    if (filterIndex !== -1) {
      // Update existing filter
      const updatedFilters = [...selectedFilters];
      updatedFilters[filterIndex] = { id: filterId, label: filterLabel, searchTerm };
      setSelectedFilters(updatedFilters);
    } else {
      // Add new filter
      setSelectedFilters([...selectedFilters, { id: filterId, label: filterLabel, searchTerm }]);
    }


    setShowResults(false);
    setActualOption("SELECT_FILTER");
    setSearchTerm("")
  };
 

  const handleInputFocus = () => {
    setShowResults(true);
  };

  const handleInputBlur = () => {
    setShowResults(false);
    // handleResetForm();
  };

  const handleResetForm = () => {
    setSearchTerm("");
    setShowResults(false);
    setPlatformSelect(null);
  };

  const handleRemoveFilter = (filterId) => {
    const updatedFilters = selectedFilters.filter((filter) => filter.id !== filterId);
    setSelectedFilters(updatedFilters);

    const data = {
      ...updatedFilters.reduce((acc, filter) => {
        acc[filter.id] = filter.searchTerm;
        return acc;
      }, {}),
      // plataforma: platformSelect?.boxitLocationId,
      itemsPerPage: 9 ,
      currentPage: 1,
    };

    handleGetData(data);
  };

  return (
      <div className="search-filters" style={{color:"gray"}}>
      <div>
        <div className="search-filters-inputs">
        <div className="search-input-container">
          <div className="search-icon-container" id="search-icon-container">
            <FaSearch className="search-icon" />
          </div>

          <div className="form-floating">
            <input
              className="search-filters-input form-control font-small"
              type="text"
              placeholder={translate("global.search")+"..."}
              value={searchTerm}
              onChange={handleSearch}
              onFocus={handleInputFocus}
              // onBlur={handleInputBlur}
            />
          </div>
          
          
          {selectedFilters && <div className="ml-2 text-under-ref" >  
              <small onClick={()=>handleResetFilters()}>{translate("global.clearFilters")}</small>
          </div>} 
        </div>

           {/* Selected filters as tags */}
          <div className="selected-filters">
            
            {selectedFilters.map((filter) => (
              <div key={filter.id} className="selected-filter">
                <div style={{fontSize:"12px", fontWeight:"bold"}}>
                  {filter.label}
                </div> 
                <div>
                  {filter.searchTerm}
                </div>
                <button
                  type="button"
                  className="btn btn-sm"
                  style={{position:"absolute", top:"-5px", right:"0", color:"white", background:"red", padding:"0 5px", borderRadius:"50px"}}
                  onClick={() => handleRemoveFilter(filter.id)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
    

        {showResults && (
          <ul className="search-filters-results font-small">
            {parameters.map((filter) => (
              <li key={filter.id} onClick={() => handleSelectFilter(filter.id)}>
                <FaCaretRight /> {" "}
                <span
                  style={{ fontStyle: "italic", textDecoration: "underline" }}
                >
                  {filter.label}
                </span>{" "}
                : <strong>{searchTerm}</strong>
              </li>
            ))}

            {/* --- PlatformSearch --- */}
        {/* {
          viewPlatforms && (
            <div className="m-2">
            <div>
              Filtrar por Boxit:
            </div>
        <PlatformSearchList
          register={register}
          errors={errors}
          setPlatformSelect={setPlatformSelect}
          platfSelect={platformSelect}
          valueId={"plataforma"}
          readOnly={false}
          setValue={setValue}
        />
      </div>
          )
        } */}
           
          </ul>
        )}
      </div>
      </div>
    </div>

  );
};

export default SearchFilters;
