import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BiLogIn, BiHome } from "react-icons/bi";
import { FiUserPlus } from "react-icons/fi";
import { SlArrowRight } from "react-icons/sl";
import { FaParachuteBox } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdAttachMoney } from "react-icons/md";
import { LuBird } from "react-icons/lu";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoNewspaper } from "react-icons/io5";

import { useLocation } from "react-router-dom";
import packageJson from "../../../../package.json";
import "./index.css";
// import TraductorList from "../../Traductor";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [active, setActive] = useState(false);

  const menuItems = [
    {
      title: "Inicio",
      url: "/",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
      viewTitle: false,
      subMenu: [
        {
          title: "Home",
          url: "#section-home",
          icon: <BiHome />,
        },
      ],
    },
    {
      title: "Información",
      url: "/planes",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
      viewTitle: true,
      subMenu: [
        {
          title: "Trae tus paquetes",
          url: "#section-stepsbuy",
          icon: <FaParachuteBox />,
        },
        // {
        //   title: "Articulos restringidos",
        //   url: "#section-articles-restrinct",
        //   icon: <MdBlock />,
        // },
        {
          title: "Beneficios",
          url: "#section-benefits",
          icon: <FaCheckCircle />,
        },
        {
          title: "Preguntas frecuentes",
          url: "#section-questions-frecuents",
          icon: <FaRegQuestionCircle />,
        },
        {
          title: "Términos y Condiciones",
          url: "https://public.loquepedi.com/Terminos_y_Condiciones_Llego_App.pdf",
          icon: <IoNewspaper />,
          openNewtab: true
          ,
        },
      ],
    },
    {
      title: "Servicios",
      url: "#footer",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
      viewTitle: true,
      subMenu: [
        {
          title: "Sucursales",
          url: "#section-locations",
          icon: <FaLocationDot />,
        },
        {
          title: "Tarifas",
          url: "#section-plans",
          icon: <MdAttachMoney />,
        },
      ],
    },
    {
      title: "Nosotros",
      url: "#section-aboutUs",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
      viewTitle: true,
      subMenu: [
        {
          title: "LLEGÓ",
          url: "#section-aboutUs",
          icon: <LuBird />,
        },
        {
          title: "Contacto",
          url: "#section-contact",
          icon: <FaPhoneVolume />,
        },
      ],
    },
  ];

  const optionsAccounts = (
    <div className="d-flex align-items-center" style={{ gap: "10px" }}>
      {/* <TraductorList /> */}
      <a href={"/login"} className="mr-2">
        <button className="btn-login">
          <BiLogIn className="mr-2" /> Mi cuenta
        </button>
      </a>
      <a href={"/registrar"}>
        <button className="btn-register">
          <FiUserPlus className="mr-2" /> Regístrate
        </button>
      </a>
    </div>
  );

  const handleClick = () => {
    setActive(!active);
  };

  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`navbar ${showBackground ? "navbar-scrolling" : ""}`}>
        {/* --- NavBar Desktop --- */}
        <a href="/">
        <div className={`navbar-logo`}>
          <img src="../img/logo-llego-bordes.png" alt="Llego" width="50" />
        </div>
        </a>
       
        <div className="menu-icon" onClick={handleClick}>
          {active ? <AiOutlineClose /> : <GiHamburgerMenu />}
        </div>

        <div className={active ? "nav-menu active navbar-sticky" : "nav-menu"}>
          {menuItems.map((item, index) => {
            return (
              <>
                <li key={index} style={{ position: "relative" }}>
                  <a
                    href={item.viewTitle ? "/" : item.url}
                    className={`nav-links col-sm-6 col-lg-3 ${
                      item.url === currentPath && "active"
                    }`}
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle={item.viewTitle ? "dropdown" : ""}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                   <span>{item.title}</span> 
                  {item.viewTitle ? (<IoIosArrowDropdownCircle className="ml-2"/> ) : ""} 
                  </a>
                  {active && <hr className="" />}

                  {/* SUBMENU */}
                  <div
                    className="dropdown-menu"
                    style={{ marginTop: "20px", background: "#121212", color:"white", width:"max-content", padding:"0 15px", boxShadow:"rgb(98 98 98) 0px 0px 5px 0px" }}
                    aria-labelledby={"navbarDropdownMenuLink"}
                  >
                    {item.subMenu.map((subItem, index) => (
                      <a
                        href={subItem.url}
                        className="navbar-mob-item"
                        target={subItem.openNewtab ? "_blank" : "_self"} rel="noopener noreferrer"
                      >
                        <div className="navbar-mob-item-title">
                          <div className="icon-section-mob">{subItem.icon}</div>
                          <span>{subItem.title}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </li>
              </>
            );
          })}
        </div>

        {optionsAccounts}

        {/* --- END / NavBar Desktop --- */}

        {/* Navbar Mobile */}
        <div
          className={`navbar-mob-background ${active ? "active" : ""}`}
          onClick={handleClick}
        ></div>
        <div className={`navbar-mobile ${active ? "active" : ""}`}>
          <div className="navbar-mob-content">
            <div
              style={{
                margin: "0 0 16px 8px",
                fontWeight: "700",
                fontSize: "28px",
              }}
            >
              <img
                src="../img/logo-llego-bordes.png"
                alt="llego-white.png"
                width={70}
              />{" "}
              Hello!
            </div>

            <div
              className="d-flex align-items-center mb-3"
              style={{ gap: "10px" }}
            >
              <a
                href={"/login"}
                className="mr-2"
                style={{
                  padding: "5px 10px",
                  border: "1px solid white",
                  color: "white",
                  borderRadius: "8px",
                  borderStyle: "dashed",
                }}
              >
                <div>
                  <BiLogIn className="mr-2" /> Mi cuenta
                </div>
              </a>
              <a href={"/registrar"}>
                <div
                  style={{
                    padding: "5px 10px",
                    border: "1px solid #f9ed32",
                    color: "#f9ed32",
                    borderRadius: "8px",
                    borderStyle: "dashed",
                  }}
                >
                  <FiUserPlus className="mr-2" /> Regístrate
                </div>
              </a>
            </div>

            <div className="navbar-mob-options">
              {menuItems.map((item, index) => {
                return (
                  <div className="navbar-mob-section">
                    {item.viewTitle && (
                      <div className="font-bold">
                        <span>{item.title}</span>
                      </div>
                    )}

                    {item.subMenu.map((subItem, index) => {
                      return (
                        <a
                          onClick={handleClick}
                          href={subItem.url}
                          className="navbar-mob-item"
                        >
                          <div className="navbar-mob-item-title">
                            <div className="icon-section-mob">
                              {subItem.icon}
                            </div>
                            <span>{subItem.title}</span>
                          </div>

                          <SlArrowRight />
                        </a>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

          <p className="text-muted font-xsmall text-center mb-0 mt-2">
            v{packageJson.version}
          </p>
        </div>
        {/* --- END / Navbar Mobile --- */}
      </header>
    </>
  );
};

export default Header;
